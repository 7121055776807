.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.row {
  display: flex;
  gap: 16px;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.labelWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 8px;
}

.error {
  margin: 0;
  color: #ef4444;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}