.wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .elipsisContainer {
      display: flex;
      gap: 10px;
    }
  }

  .title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 85.714% */
  }

  .dueDate {
    color: #9199a6;

    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    line-height: 32px; /* 200% */
  }

  .text {
    display: flex;
    width: fit-content;
    padding: 2px 4px;
    color: white !important;
    border-radius: 2px;
  }
  .low {
    background: #42a5f5;
  }
  .medium {
    background: #fbc02d;
  }
  .high {
    background: #ff1744;
  }
  .content {
    display: flex;
    flex-direction: column;

    gap: 15px;

    textarea {
      border-radius: 5px;
      border: 1px solid #cfcfcf;
      padding: 10px;
      background: rgba(152, 152, 174, 0.1);
      height: 125px;
      width: 100%;
      outline: none;
    }
    .headerText {
      color: #000;

      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
  }
}
