.paginatedSelectWrapper {
  position: relative;
}

.headerSelectWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 30px;
  padding: 4px;
  border: 1px solid black;
  border-radius: 20px;

  &.open {
    border-radius: 10px 10px 0 0;
  }
}

.selectedOptionsWrapper {

  flex: 1 1 auto;
  display: flex;
  min-width: 250px;
  max-width: 250px;
  overflow-y: scroll;
  gap: 5px;
}

.subOptionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

  padding-left: 5px;

  max-height: 80px;
  overflow-y: scroll;
}

.subOptionWrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}

.optionsWrapper {
  position: absolute;
  left: 0;
  top: 30px;
  background-color: white;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  padding: 5px;

  border: 1px solid black;
  border-top: 0;
  border-radius: 0 0 10px 10px;
}

.optionWrapper {
  width: 100%;
}

.headerOptionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  height: 32px;

  user-select: none;

  width: 100%;
  line-height: 17px;
  padding: 7.5px 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #e5e5e5;
}

.selectedOptionWrapper {
  display: flex;
  align-items: center;
  gap: 5px;

  width: fit-content;

  user-select: none;

  text-wrap: nowrap;

  padding: 3px 10px;
  border-radius: 20px;

  color: black;
  background-color: #FFC3C3;
}

.pointer {
  cursor: pointer;
}

.search {
  width: 100%;
  height: 32px;
  margin: 5px 0;
  border-radius: 6px;
  border: 1px solid #e5e7eb;
  outline: none;

  padding-left: 15px;
}

.openIcon {
  transform: rotate(-90deg);
  min-width: 24px;
  width: 24px;
  height: 24px;
}