.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .automationColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .automationRow {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 10px 10px;
      border: 1px solid rdba(255, 255, 255, 0.1);
      box-shadow: 0px 2px 10px 0px rgba(12, 38, 56, 0.1);
    }
  }
}
