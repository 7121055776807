.wrapper {
  display: flex;
  flex-direction: column;

  & > span:nth-child(1) {
    padding-bottom: 10px;
    color: #000;

    font-family: Source Sans Pro;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 85.714% */
  }
  .select {
    display: flex;
    gap: 15px;

    .assignedAttributes {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 20px;
      margin-bottom: 10px;

      border-bottom: 1px dashed #9898ae;
      label {
        color: #0c2638;

        font-family: Source Sans Pro;
        font-size: 16px;
        font-style: normal;
        height: 32px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      & > div:nth-child(2) {
        border-radius: 5px;
        border: 1px solid #cfcfcf;
        padding: 10px;
        background: rgba(152, 152, 174, 0.1);

        & > div:nth-child(1) {
          display: flex;
          gap: 5px;
          flex-wrap: wrap;
        }
      }
    }
  }

  .labelWrapper {
    & > span:nth-child(1) {
      color: #0c2638;

      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      height: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .excludedCalendar {
      padding-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      height: 100%;
    }
    .resetTitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      padding-bottom: 20px;
    }
  }

  textarea {
    display: flex;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #cfcfcf;
    width: 100%;
    height: 125px;
    line-height: 20px;
  }
  input {
    outline: none;
    padding: 2px 10px;
  }
}
