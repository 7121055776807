.gridTable {
  position: relative;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  height: 100%;
  overflow: scroll;
}

.gridTable::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.gridTable::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.gridTable::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.gridTable::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.gridTable {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.gridTable {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.row {
  display: flex;

  &.header {
    padding: 0 8px;
  }

  &.valid {
    background-color: #bbf7d049;
  }

  &.invalid {
    background-color: #ff00001f;
    box-shadow:
      0 2px 0 inset #ff0000,
      2px 0 0 inset #ff0000,
      -2px 0 0 inset #ff0000,
      0 -3px 0 inset #ff0000;
  }
}

.header {
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 1;
  height: 35px;

  width: 100%;

  background-color: white;

  color: #4a5568;
  padding: 0 8px;
  line-height: 35px;
  font-weight: bold;
  white-space: nowrap;

  border-bottom: 1px solid #e2e8f0;

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.selected {
    overflow: visible;
    box-shadow:
      0 2px 0 inset #2b6cb0,
      2px 0 0 inset #2b6cb0,
      -2px 0 0 inset #2b6cb0,
      0 -1px 0 inset #e2e8f0;
    border: 0;
  }
}

.cell {
  position: relative;
  width: 100%;

  // background-color: white;

  border-bottom: 1px solid #e2e8f0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.selected {
    outline: 2px solid #2b6cb0;
    overflow: visible;
    outline-offset: -2px;
  }

  &.columnSelected {
    overflow: visible;
    box-shadow:
      2px 0 0 inset #2b6cb0,
      -2px 0 0 inset #2b6cb0,
      0 -1px 0 inset #e2e8f0;
    border-bottom: 0;
  }

  &.error {
    background-color: #ff00001f;
  }
}

.checkboxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: calc(45px + 8px) !important;
  max-width: calc(45px + 8px) !important;
  padding: 0 0 0 8px;
}
