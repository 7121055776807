@import '~react-big-calendar/lib/css/react-big-calendar.css';

.calendar {
  height: 600px;
  margin-bottom: 70px;

  .rbc-row-segment {
    margin-top: 15px;
    margin-bottom: -5px;
  }
  .rbc-date-cell {
    @include directify($directions) {
      text-align: directed('left');
      #{directed('padding-right')}: 0px;
    }

    a {
      display: block;
      width: 100%;
      @include directify($directions) {
        #{directed('padding-left')}: 10px;
      }
      padding-top: 5px;
      font-weight: 500;
      font-size: 13px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }

  .rbc-header {
    height: 40px;
    display: flex;
    color: $color-additional;


    @media screen and (max-width: 600px) {
      white-space: pre-wrap;
    }

    @media screen and (max-width: 400px){
      height: 20px;
    }

    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
    }

    span {
      margin: auto;
      text-transform: uppercase;
      font-size: 12px;
    }

    a {
      display: block;
      margin: auto;
    }
  }

  .rbc-time-slot {
    display: flex;

    span {
      margin: auto 0;
    }
  }

  .rbc-off-range-bg {
    background-color: transparent;
  }

  .rbc-event {
    height: 20px;
    font-size: 10px;
  }

  .rbc-toolbar-label {
    text-transform: uppercase;
    font-weight: 700;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  .rbc-label {

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  .rbc-show-more {
    background-color: transparent;
  }

  .rbc-toolbar button {
    height: 30px;
    border-radius: 10px;
    border: none;
    font-size: 11px;
    color: $color-additional;
    transition: all 0.3s;
    padding: 8px 14px;
    cursor: pointer;

    @include themify($themes) {
      background-color: themed('colorHover');
    }

    &.rbc-active {
      box-shadow: none;
      background-color: #ededed;
      pointer-events: none;

      @include themify($themes) {
        background-color: themed('colorFieldsBorder');
      }
    }

    &:hover {

      @include themify($themes) {
        color: themed('colorText');
        background-color: darken(themed('colorHover'), 2%);
      }
    }

    &:focus, &:active {
      outline: none;
      box-shadow: none;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    span {
      font-size: 8px;
      font-weight: 700;
    }
  }

  .rbc-btn-group:first-child {

    button {
      @include directify($directions) {
        #{directed('margin-right')}: 5px;
      }
      padding: 8px 11px;

      &:last-child:not(:first-child), &:not(:first-child):not(:last-child),
      &:first-child:not(:last-child) {
        border-radius: 3px;
      }
    }
  }

  .rbc-btn-group:last-child {

    @media (max-width: 500px) {
      margin-top: 10px;
    }
  }

  .rbc-btn-group {
    min-width: 156px;
  }

  .rbc-header + .rbc-today {
    &:before {
      content: "";
      height: 3px;
      width: 100%;
      background-color: $color-accent;
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
    }
  }

  .rbc-today {
    background-color: transparent;
    position: relative;
  }

  .rbc-current-time-indicator {
    background-color: $color-accent;

    &:before {
      background-color: $color-accent;
    }
  }

  &.calendar--small {
    height: 400px;

    .rbc-toolbar-label {
      font-size: 10px;
      padding: 0 5px;
    }

    .rbc-btn-group {
      min-width: auto;

      button {
        padding: 4px 8px;
      }
    }
  }

  .rbc-overlay {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);

    @include themify($themes) {
      color: themed('colorText');
      background-color: themed('colorBackground');
    }
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid #DDD !important;
  }

  .rbc-time-content > * + * > * {
    border: none;
    @include directify($directions) {
      #{directed('border-left')}: 1px solid #DDD;
    }
  }

  .rbc-time-header-content {
    border: none;
    @include directify($directions) {
      #{directed('border-left')}: 1px solid #DDD;
    }
  }

  .rbc-header + .rbc-header {
    border: none;
    border-bottom: 1px solid #DDD;
    @include directify($directions) {
      #{directed('border-left')}: 1px solid #DDD;
    }
  }

  .rbc-day-bg + .rbc-day-bg {
    border: none;
    @include directify($directions) {
      #{directed('border-left')}: 1px solid #DDD;
    }
  }


  .rbc-day-slot .rbc-events-container {
    @include directify($directions) {
      #{directed('margin-right')}: 14px !important;
      #{directed('margin-left')}: 0 !important;
    }
  }

  .rbc-event-content {
    white-space: nowrap;
  }
  .rbc-time-column {
    left: -1px;
  }
}

.calendar-label {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  @include directify($directions) {
    #{directed('margin-right')}: 5px;
  }

  &.calendar-label--red {
    background-color: $color-red;
  }

  &.calendar-label--green {
    background-color: $color-accent;
  }

  &.calendar-label--blue {
    background-color: $color-blue;
  }

}
.date-picker{
  position: relative;
  .react-datepicker-wrapper{
    position: relative;
    z-index: 3;
  }
  .date_icon{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: #979ca7;
    padding: 5px 10px;
    border-radius: 0 5px 5px 0;
    display: flex;
    z-index: 1;
    img{
      max-width: 15px;
    }
  }
}

// Full calander
.fullcalander_wrap{
  .fc-view-harness{
  }
  h2{
    font-family: var(--SourceSansPro);
  }
  h1{
    font-family: var(--SourceSansPro);
  }
  .fc-daygrid-event{
    white-space: unset !important;
  }
  .fc-event-time{
    display: none !important;
  }
  .fc-button{
    border-color: $color-orange;
    background-color: $color-orange !important;
    border-color: $color-orange !important;
    font-family: var(--SourceSansPro);
    font-size: 17.5px;
    line-height: 21px;
    font-weight: 600;
    padding: 7px 20px !important;
    border-radius: 5px !important;
    margin-right: 5px !important;
    font-size: 17.5px !important;
    overflow: hidden !important;
    text-transform: capitalize !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:before{
      position: absolute;
      left: 0;
      height: 0;
      width: 0;
      border-radius: 50%;
      background-color: $color-orange-hover !important;
      transform: translate(-50%, -50%);
      transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
      z-index: -1;
      content: "";
      top: 0;
    }
    &:hover{
      border-color: $color-orange-hover !important;
      background-color: $color-orange-hover !important;
      box-shadow: 0 2px 20px rgba(0,0,0,0.12);
      -webkit-box-shadow: 0 2px 20px rgba(0,0,0,0.12);
      -moz-box-shadow: 0 2px 20px rgba(0,0,0,0.12);
      &::before{
        height: 500%;
        width: 225%;
      }
    }
  }
  .fc-header-toolbar{
    padding: 15px;
    .fc-toolbar-title{
      font-size: 24px;
      display: inline-block;
      font-weight: 700;
      vertical-align: middle;
    }
    .fc-next-button,.fc-prev-button{
      color: $black !important;
      background: none !important;
      border: none;
      outline: none !important;
      box-shadow:none !important;
      font-size: 18px;
      padding: 0 !important;
    }
    .fc-button-primary{
      &:focus{
        box-shadow: none !important;
      }
    }
  }
  .fc-col-header {
    .fc-col-header-cell{
      padding: 10px 0;
      text-transform: uppercase;
    }
  }
  .fc-daygrid-event-dot{
    display: none;
  }
  .fc-daygrid-dot-event,.fc-daygrid-block-event{
    color:$theme-black !important;
    padding-left: 15px;
    background-color: #f1eded !important;
    &.blueColor{
      border-left: 5px solid $link-color !important;
    }
    &.orangeColor{
      border-left: 5px solid $color-orange !important;
    }
    .fc-event-title{
      font-weight: normal;
    }
  }
  .fc-daygrid-event{
    &.orangeColor{
      border-left: 5px solid $color-orange !important;
    }
    &.redColor{
      border-left: 5px solid $color-error !important;
    }
  }
  .fc-h-event{
    .fc-event-main{
        color:$theme-black !important;
      // padding-left: 15px;
      // border-left: 5px solid #949494;
    }
  }
  .fc-direction-ltr .fc-daygrid-event.fc-event-start{
    margin-left: 0 !important;
  }


// week column
  .fc-timegrid-col{
    .fc-v-event{
      .fc-event-main{
        color:$theme-black !important;
        .fc-event-title{
          padding-left: 10px;
          position: relative;
          &:before{
            position: absolute;
            left: 0;
            width: 5px;
            height: 100%;
            content: "";
            background:#949494;
          }
        }
      }
    }
    .fc-timegrid-event{
      border: none !important;
      &.orangeColor{
        .fc-event-title{
          &:before{
            background:$color-orange !important;
          }
        }
      }
      &.blueColor{
        .fc-event-title{
          &:before{
            background:$link-color !important;
          }
        }
      }
      &.redColor{
        .fc-event-title{
          &:before{
            background:$color-error !important;
          }
        }
      }
    }
  }

}
