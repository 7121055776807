.emailHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  flex: 0 0 72px;
  border-bottom: 1px solid #ebedf0;
}

.navigationWrapper {
  display: flex;
  align-items: center;
  gap: 10px
}

.leftSideHeaderWrapper{
  display: flex;
  align-items: center;
  gap: 15px;
}

.actionsHeaderWrapper {
  display: flex;
  gap: 5px;
}


.separator {
  width: 1px;
  height: 40px;
  background-color: #ebedf0 ;
}

.right {
  transform: rotate(180deg);
  min-width: 24px !important;
  height: 24px !important;
}

.smallSquareButton {
  width: 32px;
  height: 32px;
  padding: 4px;

}
