.azureAdButton{ 
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 8px 20px;
  border-radius: 5px;
  width: 100%;
  font-weight: bold;
  background-color: white;
  border: 1px solid #d4d9db;
  svg {
    width: 20px;
    height: 20px;
  }
}