.sideBarWrapper {
  flex: 0 0 255px;

  padding: 20px 12px;
  height: calc(100vh - 70px);
  overflow-y: scroll;

  border-right: 1px solid #ebedf0;

  transition: 0.3s ease 0s;

  &.close {
    flex: 0 0 65px;
  }

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  li {
    list-style: none;
  }
}