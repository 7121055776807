.wipListSection {
  height: fit-content;
  padding: 0;
}

.header {
  color: #111827 !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px !important;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
}

.navigationWrapper {
  display: flex;
  gap: 16px;
}