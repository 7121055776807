.navbarWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;

  .navContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 16px;
  }
}
