.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sectionWrapper {
  padding: 0 0 16px 0;
}