@use 'src/scss/base/spacing.module.scss';

$headerFontColor: #111827;
$fontColor: #6B7280;

.header {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  color: $headerFontColor !important;
}

.subHeader {
  line-height: 24px;
  margin: 0;
  color: $headerFontColor !important;

  &.semibold {
    font-weight: 500;
  }

  &.bold {
    font-weight: 600;
  }

  &.small {
    font-size: 14px;
  }

  &.medium {
    font-size: 16px;
  }

  &.large {
    font-size: 18px;
  }

}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: $fontColor !important;

  &.thin {
    font-weight: 400;
  }

  &.normal {
    font-weight: 500;
  }

  &.bold {
    font-weight: 700;
  }

  &.grey {
    color: $fontColor !important;
  }

  &.lightGrey {
    color: #9FADC1 !important;
  }

  &.black {
    color: black !important;
  }

  &.small {
    font-size: 12px;
  }

  &.medium {
    font-size: 14px;
  }

  &.large {
    font-size: 16px;
  }

}