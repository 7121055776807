$headerBackgroundColor: #F9FAFB;
$fontColor: #6B7280;
$bodyFontColor: black;
$borderColor: #E5E7EB;

.tableContainer {
  overflow: scroll;
  position: relative;
}

.centerT {
  position: absolute;
  top: calc(50% + 12px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.tableWrapper {
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  width: 100%;
}

// table header

.tableHeader {
  background-color: $headerBackgroundColor;
  font-weight: 600;
  color: $fontColor;
  border-bottom: 1px solid $borderColor;
}

.tableHeaderRow {}

.tableHeaderCell {
  font-size: 12px !important;
  padding: 16px;
  line-height: 18px;
  text-wrap: nowrap;
}

// table body

.tableBody {}

.tableBodyRow {
  border-bottom: 1px solid $borderColor;

  th:not(:last-child) {
    margin-right: 10px;
  }

  &.pointer {
    cursor: pointer;
  }
}

.tableFullSizeRow {
  height: 100%;
}

.tableBodyCell {
  position: relative;
  padding: 16px;
  font-size: 12px;
  color: $bodyFontColor;
  font-weight: 400;
  line-height: 21px;
}

.ellipsis {
  &::before {
    content: '&nbsp;';
    visibility: hidden;
  }

  span {
    position: absolute;
    left: 16px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.center {
  span {
    text-wrap: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// table pagination

.tablePaginationWrapper {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
}

.paginationCount {
  font-size: 14px;
  color: $fontColor;
  line-height: 21px;

  span {
    color: $bodyFontColor;
  }
}