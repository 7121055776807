$inputBackgroundColor: #f9fafb;
$inputBorderColor: #d1d5db;

.formSelectWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.formSelectLabelWrapper {
  color: black;
}

.label {
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  margin: 0;
  margin-right: 8px;
}

.error {
  margin: 0;
  color: #ef4444;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
}

.calendarIcon {
  padding: 9px;
  width: 18px;
  height: 18px;
}