.wrapper {
  display: flex;
  gap: 16px;
}

.assignPropertySearchSection {
  max-width: 380px;
}

.formWrapper { 
  display: flex;
  flex-direction: column;
  gap: 16px;
}