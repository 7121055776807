.tableWrapper {
  border-collapse: separate;
  border: solid #E2E7F1 1px;
  border-radius: 8px;
  width: 100%;

  padding-right: 16px;
}

.secondaryTableRow {
  th {
    font-size: 12px !important;
    padding: 8px;
  }

  border-bottom: none;

  &.header {
    th {
      font-weight: 600;
    }
  }
}