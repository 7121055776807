.wrapper {
  display: flex;
  flex-direction: column;

  & > span:nth-child(1) {
    padding-bottom: 10px;
    color: #000;

    font-family: Source Sans Pro;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .select {
    display: flex;
    gap: 15px;

    .assignedAttributes {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 20px;
      margin-bottom: 10px;

      border-bottom: 1px dashed #9898ae;
      label {
        color: #0c2638;

        font-family: Source Sans Pro;
        font-size: 16px;
        font-style: normal;
        height: 32px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      & > div:nth-child(2) {
        border-radius: 5px;
        border: 1px solid #cfcfcf;
        padding: 10px;
        background: rgba(152, 152, 174, 0.1);

        & > div:nth-child(1) {
          display: flex;
          gap: 5px;
          flex-wrap: wrap;
        }
      }
    }
  }

  .labelWrapper {
    & > span:nth-child(1) {
      color: #0c2638;

      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      height: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .excludedCalendar {
      padding-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      height: 100%;
    }
  }

  textarea {
    display: flex;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #cfcfcf;
    width: 100%;
    height: 125px;
    line-height: 20px;
  }
  input {
    outline: none;
    padding: 2px 10px;
  }
  .colorContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50px;
    gap: 10px;

    & > span:nth-child(1) {
      display: flex;
      width: 100%;
    }
    .border {
      display: flex;
      width: 100%;
      height: 3px;
      border-radius: 12px;
    }
    .text {
      background-color: transparent !important;
    }
    .red {
      color: red;
      background-color: red;
    }
    .skylineblue {
      color: #44a6f5;
      background-color: #44a6f5;
    }
    .coralblush {
      color: #ff9784;
      background-color: #ff9784;
    }
    .mintgarden {
      color: #82c786;
      background-color: #82c786;
    }
  }

  .bottomContainer {
    margin-top: 20px;
    display: flex;
    background-color: #ccccd74f;
    padding: 16px 22px;

    justify-content: center;
    align-items: center;

    gap: 18px;

    & > button {
      border-radius: 4px;
      outline: none;
      border: 0;
      background-color: white;
      padding: 10px 33px;
      box-shadow: 0px 2px 10px 0px rgba(12, 38, 56, 0.1);
    }
  }
  .selectContact {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
