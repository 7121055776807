.wipCostListWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
}