.combinedNoteRow {

  &.localEmail {
    th {
      span {
        color: #057A55;
      }
    }
  }

  &.outlookEmail {
    th {
      span {
        color: #057A55;
      }
    }
  }

  &.generalNote {
    th {
      span {
        color: #6B7280;
      }
    }
  }

  &.taskNote {
    th {
      span {
        color: #6B7280;
      }
    }
  }

  &.sms {
    th {
      span {
        color: #1A56DB;
      }
    }
  }

  &.call {
    th {
      span {
        color: #FF6B00;
      }
    }
  }

  &.important {
    th {
      span {
        color: #E02424;
        font-weight: 700;
      }
    }
  }
}

.noteTypeCell {
  font-weight: 700;
}

.emailTableFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.searchFieldWrapper {
  width: 400px;
}