.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: fit-content;
  min-width: 420px;

  height: fit-content;
  min-height: 350px;
}