.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  top: -30px;
  font-size: 30px;
  left: -15px;
}

.noteWraper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}