.listWrapper {
  display: flex;
  flex-direction: column;
}

.listItemWrapper {
  display: flex;
  align-items: center;
  gap: 8px;

}

.listItemContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    line-height: 24px;
  }
}

.row {
  display: flex;
  gap: 8px;
}

.actionButton {
  background-color: transparent;
  border: 0;

  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
}