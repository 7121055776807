.formWrapper{ 
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.textarea {
  height: 75px;
}

.submitButton {
  width: 100%;
  justify-content: center;
}