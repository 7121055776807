.filterWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;
  width: 100%;
}

.inputWrapper {
  width: 100%;
  max-width: 384px;
}

.landlordCell {
  span {
    font-weight: 700;
    color: #F3742B;
  }
}