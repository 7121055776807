.ellipsis {
  position: relative;

  &::before {
      content: '&nbsp;';
      visibility: hidden;
  }

  span {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
}

.center {
  position: relative;

  span {
      text-wrap: nowrap;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
}

.genericTable {
  border: 0 !important;
  thead{
    tr{
      th{
        color: black !important;
      }
    }
  }
}