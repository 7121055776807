.navbarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  margin-bottom: 16px;
}
.landlordAbsent {
  display: flex;
  margin-inline: auto;
  width: fit-content;
  padding-top: 25%;
}
.navbar {
  margin: 0;
}

.sectionsWrapper {
  display: flex;
  gap: 16px;
}

.fourthPart {
  min-width: 380px;
  width: 25%;
}
