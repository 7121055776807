.row {
  display: flex;
  gap: 16px;

  &.mb4 {
    margin-bottom: 16px;
  }
}

.half {
  width: 50%;
}

.actionsWrapper {
  margin-top: 16px;
  display: flex;
  justify-content: end;
  gap: 16px
}