.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.row {
  display: flex;
  gap: 16px;

  &.bottom {
    align-items: end;
  }

  &.end {
    justify-content: end;
  }
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.editorWrapper > div {
  border-radius: 8px;
}

.labelWrapper {
  color: black;
  margin-bottom: 8px;
  text-wrap: nowrap;
}

.label {
  margin: 0;
  margin-right: 8px;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}

.error {
  margin: 0;
  color: #ef4444;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}