$activeColor: #F3F4F6;
$activeFontColor: #111928;
$borderColor: #E5E7EB;
$fontColor: #6B7280;
$subNavBarActiveColor: #F3742B;

// navbar
.navBarWrapper {
  background-color: white;
  box-shadow: 0px 1px 2px 0px #00000014;
  border-radius: 8px;
  overflow: hidden;
  width: fit-content;
}

.navBarList {
  list-style: none;
  padding: 0;
  display: flex;
}

.navBarItem {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease 0s;

  &:not(:last-child) {
    border-right: 1px solid $borderColor;
  }

  &.active {
    a {
      color: $activeFontColor;
    }

    background-color: $activeColor;
  }

  &:hover {
    a {
      color: $activeFontColor;
    }

    background-color: $activeColor;
  }
}

.navBarLink {
  display: block;
  padding: 16px 24px;
  color: $fontColor;
  line-height: 21px;
  font-weight: 600;
  font-size: 16px;
}

// sub navbar

.subNavBarWrapper {
  width: fit-content;
  margin-bottom: 16px;
}

.subNavBarList {
  list-style: none;
  padding: 0;
  display: flex;
}

.subNavBarItem {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-wrap: nowrap;
  transition: all 0.3s ease 0s;
  border-bottom: 1px solid $borderColor;

  &.active {

    a,
    span {
      color: $subNavBarActiveColor;
    }

    border-bottom: 1px solid $subNavBarActiveColor;
  }

  &:hover {

    a,
    span {
      color: $subNavBarActiveColor;
    }

    border-bottom: 1px solid $subNavBarActiveColor;
  }
}

.subNavBarLink {
  display: block;
  padding: 16px 24px;
  color: $fontColor;
  line-height: 21px;
  font-weight: 600;
  font-size: 16px;
}

.verticalNavBarWrapper {
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid $borderColor;
  background-color: white;
  height: fit-content;

  &.vertical {
    min-width: 300px;
    width: 300px;
  }
}

.verticalNavBarList {
  list-style: none;
  padding: 0;
  display: flex;

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
  }
}

.verticalNavBarItem {
  padding: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  cursor: pointer;

  &.horizontal {
    text-align: center;
    width: 100%;
    &:not(:last-child) {
      border-right: 1px solid $borderColor;
    }
  }

  &.vertical {
    &:not(:last-child) {
      border-bottom: 1px solid $borderColor;
    }
  }


  &.active {
    span {
      color: $subNavBarActiveColor;
    }

    background-color: $activeColor;
  }
}