.emailListWrapper {
  position: relative;
  padding-left: 0;
  min-height: calc(100vh - 203.5px);
  flex: 1 1 auto;
}

.notFound {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}