.row {
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
  border: 1px solid rdba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 10px 0px rgba(12, 38, 56, 0.1);
  border-radius: 5px;

  user-select: none;

  &.sub {
    justify-content: start;
  }
}

.rowTitleWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.actionWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.button {
  width: 28px;
  height: 28px;
  padding: 3px;

  svg {
    min-width: 22px;
    height: 22px;
  }
}

.subRowWraper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px 0 0 30px;
}

.subRowIcon {
  width: 20px;
  height: 20px;
}

.dropDownIcon {
  transform: rotate(-90deg);
  width: 24px;
  height: 24px;
}