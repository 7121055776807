$backgroundColor: #E9ECEF;
$incomingMessageBackgroundColor: #FFFFFF;
$outgoingMessageBackgroundColor: #000000;

.smsModalWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.smsConversationWrapper {
  position: relative;
  overflow-y: scroll;
  background-color: $backgroundColor;
  width: 100%;
  height: 600px;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &::-webkit-scrollbar {
    width: 17px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    border-left: 1px solid #cacaca;
    border-bottom: 1px solid #cacaca;
    border-top: 1px solid #cacaca;
  }

  &::-webkit-scrollbar-track {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 1px solid #cacaca;
    background-color: #ccccd7;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ffffff;
  }
}

.smsConversationItemWrapper {


  &.outgoing {
    display: flex;
    justify-content: flex-end;
  }

  &.incoming {
    display: flex;
    justify-content: flex-start;
  }

}

.smsConversationItem {
  display: flex;
  flex-direction: column;
  gap: 8px;

  border-radius: 6px;
  padding: 12px;
  max-width: 470px;
  width: 100%;

  &.outgoing {
    background-color: $outgoingMessageBackgroundColor;
    color: white;
  }

  &.incoming {
    background-color: $incomingMessageBackgroundColor;
    color: black;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;

  &.incoming {
    color: #FF8A4C;
  }
}

.date {
  font-size: 14px;
  line-height: 18px;

  &.incoming {
    color: #6B7280;
  }

  &.outgoing {
    color: #D1D5DB;
  }

}

.body {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
}

.status {
  color: #9CA3AF
}

.endText {
  text-align: center;
  color: black;
}

.textWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.text {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 0;

  &.highlight {
    color: #FF8A4C;
  }
}

.loadingWrapper {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}