.formWrapper {}

.row {
  display: flex;
  gap: 16px;
  align-items: end;
}

.editButton {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 12px;

  svg {
    width: 24px;
    height: 24px;
  }
}


.recoverableCostInput {
  color: #E02424;
}

.totalAmountLabel {
  p {
    color: black !important;
    font-weight: 700 !important;
  }
}
.totalAmountRecoverableLabel {
  p {
    color: #E02424 !important;
    font-weight: 700 !important;
  }
}

.labelWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.openTableButton {
  border: 0;
  padding: 0;
  background: none;
  color: #F3742B;
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
}