.wrapper {
  display: flex;
  align-items: end;
  gap: 15px;
  margin-bottom: 10px;
}

.listOfFees{
  width: 250px;
}

.addFeeButton{
  width: 100px;
  margin-bottom: 7px !important;  
}

.labelWrapper {
  span {
    font-size: 16px;
    color: #979ca7;
    font-weight: 400;

    p {
      font-size: 16px !important;
      line-height: 16px;
    }
  }
}