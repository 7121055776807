.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.textEditorWrapper {
  z-index: 0;
}

.error {
  color: red;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.formActionsWrapper {
  display: flex;
  gap: 10px;
  justify-content: end;
}

.sendButton {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0;

  svg {
    margin: 0;
  }
}

.cancelButton {
  background-color: #e4e8f0;
  padding: 10px 16px;
  border-radius: 6px;
  border: 0;
}