.sendEmailForm{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  margin-bottom: 20px;
}

.field {
  background-color: #F9FAFB;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
}

.bodyFiledWrapper {
  flex: 1 1 auto;
}

.sendButton {
  background-color: #1d4ed8;
  color: white;
  padding: 10px 20px;
  width: 95px;

  font-size: 16px;
  font-weight: 500;
  gap: 5px;

  &:hover{
    background-color: #1d40b0;
    color: white;
  }

  svg {
    min-width: 16px ;
    height: 16px;
  }
}

.right {
  transform: rotate(90deg);
}

.error {
  color: red !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px !important;
}
