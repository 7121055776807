.debtorName {
  font-weight: 700;
  color: #F3742B;
}

.deleteButton {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  border: 0;
  background-color: transparent;

  svg {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
}