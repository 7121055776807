.modal {
}

.login-modal-form {
  .modal-content {
    padding-top: 20px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
  }
}

.modal-dialog {
  max-width: 385px;
  margin: 25px auto;
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.modal-content {
  border-radius: 0;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 50px 40px 60px 40px;
  text-align: center;

  @include themify($themes) {
    background-color: themed('colorBackground');
    color: themed('colorText');
  }
  .form__form-group {
    text-align: left;
  }
  @include mediaq(991px) {
    padding: 30px 20px;
  }
  @include mediaq(576px) {
    padding: 20px 15px;
  }
}

.modal__close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  @include directify($directions) {
    #{directed('right')}: 15px;
  }
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;

  @include themify($themes) {
    color: themed('colorIcon');
  }
}

.modal__title-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.modal__title {
  margin-top: 10px;
  margin-bottom: 15px;

  &:first-child {
    margin-top: 0;
  }
}

.modal__footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  @include directify($directions) {
    flex-flow: directed('flex-flow') !important;
  }

  button {
    min-width: 100px;
    padding: 4px 20px;
    margin: 0 5px;
    margin-bottom: 0;
  }
}

.modal-dialog--primary {
  .modal__title-icon {
    color: $color-blue;
  }
}

.modal-dialog--success {
  .modal__title-icon {
    color: $color-accent;
  }
}

.modal-dialog--warning {
  .modal__title-icon {
    color: $color-yellow;
  }
}

.modal-dialog--danger {
  .modal__title-icon {
    color: $color-red;
  }
}

.modal-dialog--colored {
  color: white;

  .modal__title-icon {
    color: white;
  }

  .modal__close-btn {
    color: white;
  }

  .modal__footer {
    button:first-child {
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.3);
      border-color: #ffffff;

      &:before {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    button:last-child {
      border-color: white;
      color: white;
    }
  }

  &.modal-dialog--primary .modal-content {
    background-color: $color-blue;
  }

  &.modal-dialog--success .modal-content {
    background-color: $color-accent;
  }

  &.modal-dialog--warning .modal-content {
    background-color: $color-yellow;
  }

  &.modal-dialog--danger .modal-content {
    background-color: $color-red;
  }
}

.modal-dialog--header {
  max-width: 520px;

  .modal-content {
    padding: 0;
    @include directify($directions) {
      text-align: directed('left');
    }
  }

  .modal__header {
    color: white;
    padding: 15px 20px;
    position: relative;
  }

  .modal__title {
    margin: 0;
    font-weight: 300;
  }

  .modal__close-btn {
    color: white;
    top: calc(50% - 8px);
  }

  .modal__body {
    @include directify($directions) {
      #{directed('padding-right')}: 40px;
      #{directed('padding-left')}: 20px;
    }
    padding-top: 25px;
    padding-bottom: 20px;
  }

  .modal__footer {
    margin-bottom: 40px;
    justify-content: flex-end;
    @include directify($directions) {
      #{directed('padding-right')}: 20px;
    }
  }

  &.modal-dialog--primary .modal__header {
    background-color: $color-blue;
  }

  &.modal-dialog--success .modal__header {
    background-color: $color-accent;
  }

  &.modal-dialog--warning .modal__header {
    background-color: $color-yellow;
  }

  &.modal-dialog--danger .modal__header {
    background-color: $color-red;
  }
}

.tool_modal {
  &.small_modal {
    .modal-dialog {
      max-width: 490px;
    }
  }
  &.mediam_modal {
    .modal-dialog {
      // max-width: 550px;
      width: max-content;
    }
  }
  &.noheader {
    .modal-header {
      border-bottom: none;
    }
  }
  .modal-dialog {
    // max-width: 1000px;
    max-width: max-content;
  }
  .modal-content {
    padding: 20px 30px 25px 30px;
  }
  .modal-title {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #8798ad;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    font-weight: 400;
  }
  .modal-body {
    button {
      padding: 10px 20px;
      background: none;
      border: none;
      font-size: 17.5px;
      font-weight: 600;
      line-height: 21px;
      font-family: var(--SourceSansPro);
      box-shadow: 0 2px 10px 0 #dadefd, 0 2px 20px 0 rgba(224, 231, 255, 0.2);
      &:hover {
        background-color: $color-orange;
        color: $theme-white;
      }
      &.btn-secondary {
        box-shadow: none;
        background: $color-orange;
      }
      @include mediaq(767px) {
        font-size: 14px;
        line-height: 16.8px;
      }
    }
    .file_wrap {
      max-width: 250px;
      margin: 15px auto;
      input[type='file'] {
        box-shadow: 0 2px 10px 0 #dadefd, 0 2px 20px 0 rgba(224, 231, 255, 0.2);
        padding: 10px;
        background: none;
        border: none;
        font-size: 14px;
        font-weight: 700;
        opacity: 0;
        position: absolute;
        z-index: 3;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        cursor: pointer;
        // &:hover{
        //   background: $color-blue;
        //   color: $theme-white;
        // }
      }
      span {
        display: block;
        padding: 10px 25px;
        border: 0;
        margin: 0 auto;
        font-size: 15px;
        letter-spacing: 0.05em;
        cursor: pointer;
        box-shadow: 0 2px 10px 0 #dadefd, 0 2px 20px 0 rgba(224, 231, 255, 0.2);
        color: $theme-black;
        outline: none;
        cursor: pointer;
        font-weight: 700;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
      }
    }
    .tool_popup_tbale {
      background-color: #f9f9f9;
      padding: 20px 10px;
      margin-bottom: 35px;
      max-width: 100%;
      overflow: auto;
      table {
        font-family: var(--SourceSansPro);
        thead {
          th {
            color: #979ca7;
            text-align: left;
            font-size: 14px;
            font-weight: 700;
            &:first-child {
              text-align: center;
              min-width: 84px;
              max-width: 84px;
            }
            &:nth-child(2) {
              min-width: 92px;
              max-width: 92px;
            }
            &:nth-child(3) {
              min-width: 138px;
              max-width: 138px;
            }
            &:nth-child(4) {
              min-width: 200px;
              max-width: 200px;
            }
            &:nth-child(5) {
              min-width: 83px;
              max-width: 83px;
            }
            &:nth-child(6) {
              min-width: 62px;
              max-width: 62px;
            }
            &:nth-child(7) {
              min-width: 111px;
              max-width: 111px;
            }
            &:nth-child(8) {
              min-width: 69px;
              max-width: 69px;
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid #dadada;
            td {
              text-align: left;
              font-size: 14px;
              padding: 10px 3px;
              &:first-child {
                text-align: center;
              }
              .label-checkbox {
                margin-bottom: 0;
                vertical-align: sub;
                input[type='checkbox'] {
                  display: none;
                  & + .checkbox-indicator {
                    width: 19px;
                    height: 18px;
                    border: 1px solid;
                    border-color: #979ca7;
                    border-radius: 3px;
                    display: block;
                    background-color: #fff;
                    position: relative;
                  }
                  &:checked + .checkbox-indicator {
                    border-color: $color-accent;
                    background-color: $color-accent;
                  }
                  &:checked + .checkbox-indicator::before,
                  &:checked + .checkbox-indicator::after {
                    content: '';

                    position: absolute;
                    top: 8px;
                    @include directify($directions) {
                      #{directed('left')}: 1px;
                    }

                    width: 15px;
                    height: 2px;

                    background-color: white;
                    opacity: 1;
                    transition: 0.2s;
                  }

                  &:checked + .checkbox-indicator::before {
                    transform: rotate(45deg);
                    @include directify($directions) {
                      #{directed('left')}: 2px;
                    }
                    top: 9px;
                    height: 1px;
                    width: 6px;
                    left: 2px;
                  }

                  &:checked + .checkbox-indicator::after {
                    transform: rotate(-45deg);
                    @include directify($directions) {
                      #{directed('left')}: 6px;
                    }
                    top: 7px;
                    height: 1px;
                    width: 10px;
                    right: 2px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// add task Modal
.addtaskModal {
  .modal__body {
    padding: 0 25px;
    @include mediaq(991px) {
      padding: 0 10px;
    }
  }
  h4 {
    padding-top: 20px;
  }
  .form__form-group-label {
    width: 100%;

    .required-label {
      color: '#ff0000';
    }
  }
  .modal-content {
    text-align: left;
  }
  .btn {
    padding: 8px 20px;
    margin-bottom: 14px;
  }
  .form__form-group-label {
    display: block;

    .required-label {
      color: '#ff0000';
    }
  }
  .innerContent {
    padding: 25px 30px 10px;
    @include mediaq(991px) {
      padding: 20px 10px;
    }
  }
  .modal_input {
    width: 100%;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 16.8px;
    border-radius: 5px;
    height: 32px;
    transition: border 0.3s;
    background: $colorBackgroundBody;
    font-family: var(--SourceSansPro);
    font-weight: 400;
    outline: none;
    border: 1px solid #dadada;
    position: relative;
    .geosuggest__input {
      width: 100%;
      border: none;
      background: none;
      outline: none;
    }
    .geosuggest__suggests-wrapper {
      position: absolute;
      background: $white;
      z-index: 3;
      top: 100%;
      width: 100%;
      left: 0;
    }
  }
  .modal_textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 16.8px;
    border-radius: 5px;
    transition: border 0.3s;
    background: $colorBackgroundBody;
    font-family: var(--SourceSansPro);
    font-weight: 400;
    outline: none;
    border: 1px solid #dadada;
    height: 100px;
  }
}

.summary {
  position: relative;
  top: -40px;
  padding: 20px 0px 0px 124px;
}
.summary-value {
  color: #0a090999;
  font-weight: bold;
}
