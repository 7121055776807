.wipRow {
  cursor: pointer;
}

.loggedCell {
  color: #6B7280;
}

.debtorsCell {
  color: #F3742B;
  font-weight: 700;
}

.coverCell {
  font-weight: 600;
}