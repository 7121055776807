.modalWrapper {
  padding: 32px;
  height: 80vh;
  user-select: none;

  width: 80wv;
  max-width: 1040px;
  max-height: 630px;
}

.highlighted {
  color: #e12e2e;
  font-weight: 700;

  margin: 0;
  font-size: 14px;
}

.layout {
  background-color: black;
  z-index: 1000;
}
