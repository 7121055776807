.sendEmailHeaderWrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  padding: 16px;
  flex: 0 0 72px;
  border-bottom: 1px solid #ebedf0;
}

.separator {
  width: 1px;
  height: 40px;
  background-color: #ebedf0 ;
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: #6b7280;
}