$inputBackgroundColor: #f9fafb;
$inputBorderColor: #d1d5db;


.wrapper {
  width: 100%;
}


.phoneFieldWrapper {
  position: relative;
}

.iconWrapper {
  position: absolute;
  z-index: 3;
  top: 1px;
  left: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 2px);
  width: 44px;
  padding: 4px;
  background-color: white;
  border: 0;
  border-right: 1px solid $inputBorderColor;
  border-radius: 8px 0 0 8px;
  cursor: pointer;

  &.open {
    border-radius: 8px 0 0 0;
  }
}

.icon {
  width: 22px;
  height: 16px;
}

.arrow {
  width: 16px;
  height: 16px;
  transform: rotate(-90deg);
}

.selectListWrapper {
  position: absolute;
  z-index: 5;
  background-color: white;
  top: 46px;
  left: 0;
  padding: 8px;
  border-radius: 0 0 8px 8px;
  border: 1px solid $inputBorderColor;
  border-top: none;
  width: 100%;

}

.selectList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  padding: 0;
  max-height: 150px;
  overflow-y: scroll;
}

.selectListItem {
  display: flex;
  align-items: center;
  gap: 8px;
  text-wrap: nowrap;
  cursor: pointer;
}

.phoneInput {
  position: relative;
  width: 100%;
  outline: none;
  padding: 8px;
  background-color: $inputBackgroundColor;
  border: 1px solid $inputBorderColor;
  font-size: 14px;

  border-radius: 8px;

  padding: 12px 44px 12px 56px;
  font-size: 16px;
  line-height: 20px;

  &.open {
    border-radius: 8px 8px 0 0;
  }
}

.outlined {
  background-color: white;
}

.labelWrapper {
  display: block;
  color: black;
  margin-bottom: 8px;
  text-wrap: nowrap;
}

.label {
  margin: 0;
  margin-right: 8px;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}

.error {
  margin: 0;
  color: #ef4444;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}

.clearButton {
  position: absolute;
  right: 8px;
  top: 50%;
  z-index: 3;
  background-color: transparent;
  border: 0;
  padding: 0;

  transform: translateY(-50%);

  svg {
    cursor: pointer;
    width: 20px;
    height: 20px;

    path {
      stroke: #6B7280;
    }
  }
}