.modalWrapper {
  width: 80%;
  max-height: 750px;
  max-width: 1200px;
  padding: 0;
}

.smallWidth {
  width: 50% !important;
}

.description {
  overflow-y: scroll;
  height: 100%;
  min-height: 25px;
  border-bottom: 1px solid #cacaca;

  p { 
    word-wrap: break-word;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: end;
  gap: 8px;
}

.containerWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50%;
    min-width: 50%;
    padding: 30px 30px 30px 40px;

    .header {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;

      .headerAction {
        display: flex;
        flex: 1 1 auto;
        justify-content: end;
      }

      .elipsisContainer {
        display: flex;
        gap: 10px;
      }
    }

    .title {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 85.714% */
    }

    .dueDate {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &>span {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &>span:nth-child(2) {
        gap: 10px;
      }

      color: #9199a6;

      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      line-height: 32px;
      /* 200% */
    }

    label {
      color: #9199a6;

      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      /* 200% */
    }

    .clientsInfo {
      display: flex;
      flex-direction: column;

      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      line-height: 27px;
      /* 168.75% */

      padding: 10px 20% 10px 14px;

      gap: 8px;
      border-radius: 5px;
      background: rgba(204, 204, 215, 0.31);

      .clientInfoText {
        color: #ff6b00;
        font-family: Source Sans Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
      }
    }

    .devider {
      display: flex;
      border-bottom: 1px dashed #9898ae;
      padding-top: 20px;
      margin-bottom: 20px;
    }

    .note {
      display: flex;
      flex-direction: column;

      textarea {
        border-radius: 5px;
        border: 1px solid #cfcfcf;

        padding: 10px 10px;
        min-height: 100px;
      }
    }

    .noteHistory {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;

      align-items: flex-end;
      color: #ff6b00;

      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 150% */
    }

    .text {
      display: flex;
      width: fit-content;
      padding: 2px 4px;
      color: white !important;
      border-radius: 2px;
    }

    .low {
      background: #42a5f5;
    }

    .medium {
      background: #fbc02d;
    }

    .high {
      background: #ff1744;
    }

    .bottomContainer {
      display: flex;
      background-color: #ccccd74f;
      padding: 16px 22px;

      justify-content: center;
      align-items: center;

      border-radius: 5px;
      gap: 18px;

      &>button {
        border-radius: 4px;
        outline: none;
        border: 0;
        background-color: white;
        padding: 10px 33px;
        box-shadow: 0px 2px 10px 0px rgba(12, 38, 56, 0.1);
      }
    }

    a {
      color: #ff6b00;
      text-decoration: underline;
    }

    .notesHistory {
      max-height: 200px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 15px;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.3);

      &>div {
        display: flex;

        &>span:nth-child(2) {
          margin-left: 2px;
        }
      }
    }
  }

  .noteSection {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    top: 0;
    position: sticky;

    &>div:nth-child(2) {
      flex: 2 1;
      margin-top: 15%;
      overflow-y: auto;
      height: 80%;
      padding: 20px 0;
      position: sticky;
      max-height: 45vh;

      .noteItem {
        display: flex;
        padding: 10px 10px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        justify-content: space-between;

        &>div {
          gap: 10px;
          display: flex;
          align-items: center;
        }
      }
    }

    &>div:nth-child(3) {
      margin-top: auto;
      padding-top: 20px;
      bottom: 11%;
      margin-bottom: 12px;
      display: flex;
      gap: 5%;

      &>div {
        width: 70% !important;

        input {
          width: 100% !important;

          outline: none;
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 12px;
          padding: 5px 10px;
        }
      }

      button {
        width: 25%;
        outline: none;
        border: 0;
        border-radius: 6px;
        padding: 0px 5px;
        color: white;
        background-color: #f37c20;
      }
    }
  }
}

.actionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}