.wrapper {
  display: flex;
  flex-direction: column;

  & > span:nth-child(1) {
    padding-bottom: 10px;
    color: #000;

    font-family: Source Sans Pro;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 85.714% */
  }
  .select {
    display: flex;
    gap: 15px;

    .assignedAttributes {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 20px;
      margin-bottom: 10px;

      border-bottom: 1px dashed #9898ae;
      label {
        color: #0c2638;

        font-family: Source Sans Pro;
        font-size: 16px;
        font-style: normal;
        height: 32px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      & > div:nth-child(2) {
        border-radius: 5px;
        border: 1px solid #cfcfcf;
        padding: 10px;
        background: rgba(152, 152, 174, 0.1);

        & > div:nth-child(1) {
          display: flex;
          gap: 5px;
          flex-wrap: wrap;
        }
      }
    }
  }
  .dayIndicator {
    display: flex;
    padding: 10px 0;
    font-size: 24px;
    font-weight: 500;
  }

  .activeDate {
    background: rgba(0, 0, 0, 0.2);
  }
  .selectTime {
    display: flex;
    gap: 15px;
    width: 100%;
    padding: 20px 0;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & > div {
      display: flex;
      width: 100%;
      height: 100%;

      gap: 10px;
    }

    .customDate {
      display: flex;
      justify-content: center;
      align-items: center;

      border: 1px solid black;
      border-radius: 5px;
      padding: 5px 10px;
      width: 100%;
      height: 85px;

      cursor: pointer;

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.1);
      }
    }
  }

  .labelWrapper {
    & > span:nth-child(1) {
      color: #0c2638;

      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      height: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .excludedCalendar {
      padding-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      height: 100%;
    }
  }
  .addCustomTime {
    width: 20%;
  }

  textarea {
    display: flex;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #cfcfcf;
    width: 100%;
    height: 125px;
    line-height: 20px;
  }
  input {
    outline: none;
    padding: 2px 10px;
  }
  .colorContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50px;
    gap: 10px;

    & > span:nth-child(1) {
      display: flex;
      width: 100%;
    }
    .border {
      display: flex;
      width: 100%;
      height: 3px;
      border-radius: 12px;
    }
    .text {
      background-color: transparent !important;
    }
    .red {
      color: red;
      background-color: red;
    }
    .skylineblue {
      color: #44a6f5;
      background-color: #44a6f5;
    }
    .coralblush {
      color: #ff9784;
      background-color: #ff9784;
    }
    .mintgarden {
      color: #82c786;
      background-color: #82c786;
    }
  }
}
