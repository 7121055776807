.itemWrapper{
  display: flex;
  align-items: center;
  gap: 5px;
}

.openInfoButton {
  cursor: pointer;
  color: #ff6b00;

  &:hover {
    text-decoration: underline;
  }
}

.separator {
  width: 1px;
  background-color: black;
  height: 20px;
}