.sectionWrapper {
  padding: 0;
}

.filterWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 16px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.dropdown {
  width: 180px;
}

.inputWrapper {
  width: 350px;
}

.clientCell {
  font-weight: 400;
  color: #6B7280;
}

.landlordCell {
  font-weight: 700;
  color: #F3742B;
}