.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.emailsWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(33.333% - 32px), 1fr));
  grid-gap: 16px;
}

.clearButton {
  background-color: transparent;
  border: 0;
  padding: 0;

  svg {
    cursor: pointer;
    width: 100%;
    height: 100%;

    path {
      stroke: #6B7280;
    }
  }
}

.actionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}