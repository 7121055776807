.dropdownToggleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  background-color: transparent;
  border: 0;

  padding: 16px 0;

  width: 100%;
}

.dropdownToggleText {
  color: #2B6CB0;
  font-size: 14px;
  line-height: 20px;
  text-wrap: nowrap;

  margin: 0;
}

.dropdownToggleIcon {
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);

  transition: 0.3s transform ease 0s;

  &.open {
    transform: rotate(-270deg);
  }
}