.syncWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;

  width: 100%;
}

.syncText {
  font-size: 13px;
  color: black;
}