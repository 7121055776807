.wipListFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px;
}

.dropdownWrapper {
}

.search {
  width: 380px;
}