.div {
  min-height: 100%;
  min-width: 100%;

  line-height: 35px;

  padding: 0 8px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.notEditable {
    cursor: auto;
  }
}

.divInputWrapper {
  box-sizing: border-box;
  min-height: 100%;
  min-width: 100%;
  padding: 0 2px 0 8px;
}

.divInput {
  width: 100%;
  height: 35px;
  outline: none;
  background-color: transparent;
  padding: 0;
  border: 0;
}