.wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 30px;
  height: 100%;
  width: 100%;

  min-height: 500px;

  .columnAutomation {
    display: flex;
    flex-direction: column;

    min-height: 100px;

    & > span:nth-child(1) {
      font-size: 24px;
      font-weight: 600;
    }
    & > div {
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      gap: 10px;
    }
  }
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .empty {
    display: flex;
    width: 100%;
    height: 100%;

    justify-content: center;
    align-items: center;
  }
}
