.refreshButton {
  background-color: transparent;
  border: 0;

  svg {
    min-width: 24px;
    height: 24px;
  }

  &.loading {
    animation: rotate 1s linear infinite;
  }
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(-360deg);
  }
}