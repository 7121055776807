.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.textarea {
  height: 100px;
  padding: 5px;
  resize: none;
}