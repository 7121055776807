$themes: (
  light: (
    colorBackground: white,
    colorBackgroundBody: #f5f9fc,
    inboxButtonBackground: #f5f9fc,
    inboxButtonBackgroundHover: darken(#f5f9fc, 5%),
    colorHeaderDataPicker: #6db0ff,
    colorText: #646777,
    colorTextAdditional: #646777,
    logoImg: url(../../shared/img/logo/logo_light.svg),
    colorHover: #c5dff2,
    colorFolderHover: #f0eeee,
    colorBorder: #eff1f5,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #c5dff2,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #b4bfd0,
    colorFitness: #646777,
    colorEmoji: #232329,
    sidebarColor: #c5dff2,
    colorText1: rgba(14, 62, 73, 0.88),
    inputFieldBorder: #dadada,
    fieldTitle: #979ca7,
  ),
  dark: (
    colorBackground: #232329,
    colorBackgroundBody: #2a2a31,
    inboxButtonBackground: #2a2a31,
    inboxButtonBackgroundHover: lighten(#2a2a31, 5%),
    colorHeaderDataPicker: #063263,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    logoImg: url(../../shared/img/logo/logo_dark.svg),
    colorHover: #38373f,
    colorFolderHover: #ffffff1a,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorEmoji: #ffffff,
    sidebarColor: #232329,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
$checkBg: #fbfbfb;
$fieldBackground: #f5f9fc;
$color-accent: #f69066;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);
$subtitle-text: #959ba5;
$color-violet: #c88ffa;

$color-red: #ff4861;
$color-error: #ff0000;
$color-red-hover: darken($color-red, 10%);
$colorBackgroundBody: #f5f9fc;
$color-blue: #70bbfd;
$link-color: #2067ff;
$color-blue-hover: darken($color-blue, 10%);
$fieldTitle: #979ca7;

$color-orange: #ec6b1c;
$color-orange-hover: #ff8f00;
$tableHead: #646777;
$color-gray: #787985;
$color-light-gray: #d8dfe9;
$theme-white: #ffffff;
$theme-black: #000000;
$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);
$title-overdue: #ff2751;

$directions: (
  ltr: (
    direction: ltr,
    right: right,
    left: left,
    margin-right: 'margin-right',
    margin-left: 'margin-left',
    padding-left: 'padding-left',
    padding-right: 'padding-right',
    border-top-left-radius: 'border-top-left-radius',
    border-bottom-left-radius: 'border-bottom-left-radius',
    border-top-right-radius: 'border-top-right-radius',
    border-bottom-right-radius: 'border-bottom-right-radius',
    border-left: 'border-left',
    border-right: 'border-right',
    translate: 'translate(-50%, -50%);',
    mirrorY: 'scale(1, 1)',
    border-right-color: 'border-right-color',
    transform-sidebar: 'rotate(90deg)',
    flex-flow: row nowrap,
    row: 'row',
    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(0%)),
    '-': '-',
  ),
  rtl: (
    direction: rtl,
    right: left,
    left: right,
    margin-right: 'margin-left',
    margin-left: 'margin-right',
    padding-left: 'padding-right',
    padding-right: 'padding-left',
    border-top-left-radius: 'border-top-right-radius',
    border-bottom-left-radius: 'border-bottom-right-radius',
    border-top-right-radius: 'border-top-left-radius',
    border-bottom-right-radius: 'border-bottom-left-radius',
    border-left: 'border-right',
    border-right: 'border-left',
    translate: 'translate(50%, -50%);',
    mirrorY: 'scale(1, -1)',
    border-right-color: 'border-left-color',
    transform-sidebar: 'rotate(-90deg)',
    flex-flow: row-reverse nowrap,
    row: 'row-reverse',
    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(100%)),
    '-': '+',
  ),
);

@mixin directify($directions) {
  @each $direction, $map in $directions {
    .#{$direction}-support & {
      $direction-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($directions, $direction), '#{$key}');
        $direction-map: map-merge(
          $direction-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $direction-map: null !global;
    }
  }
}

@function directed($key) {
  @return map-get($direction-map, $key);
}

.viewpayment {
  padding: 0 1px;
  @media (max-width: 1000px) {
    overflow: auto;
  }
  tbody.MuiTableBody-root tr {
    border: 1px solid rgba(224, 224, 224, 1);
  }
  tfoot.MuiTableFooter-root td {
    border: none;
  }
}

.drop-here {
  border: 2px solid #cfd6df;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f9fc;
  padding: 20px;
  .drop-box {
    width: 100%;
    height: 100%;
    padding: 20px;
    border: dashed;
    border-color: #cfd6df;
    border-radius: 10px;
    background-color: #fff;
    .uploder-img {
      background-image: url('../../shared/img/draganddrop.png');
      background-size: 100px;
      background-repeat: no-repeat;
      width: 140px;
      height: 160px;
      margin: auto;
      display: block;
      background-position: center;
    }
  }
}

.uplodedfile {
  border: 2px solid #cfd6df;
  border-radius: 10px;
  padding: 10px;
  color: #979ca7;
  .file-img {
    background-image: url('../../shared/img/folder.svg');
    width: 30px;
    height: 40px;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
  }
  .view-download {
    font-size: 35px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      margin: 0 15px;
      cursor: pointer;
      &:hover {
        color: #000;
      }
    }

    a {
      font-size: 35px;
      color: #979ca7;
      &:hover {
        color: #000;
      }
    }
  }
  .file-detail {
    display: inline-block;
    vertical-align: top;
    color: #979ca7;
    span {
      font-size: 15px;
      font-weight: normal;
      display: block;
    }
  }
}

.Agent-Reports {
  padding: 20px;
  border: 1px solid #cad5ff;
  background-color: #f6f7ff;
}
.Agent-Reports-toggle {
  tbody {
    tr {
      border: 1px solid rgba(224, 224, 224, 1);
    }
    tr.blank-row {
      border: none;
    }
  }
}

.details-timeline {
  tbody {
    td {
      border: none;
    }
  }
}

.email-popup {
  .react-select__input {
    input {
      height: 100%;
    }
  }
}

.top-20 {
  top: 22px;
}
.line-height19 {
  line-height: 19px !important;
}
.line-height36 {
  line-height: 38px !important;
}

.dropzon-custom {
  width: calc(100% - 60px);
}

.dropzone.dropzone--single:focus {
  outline: none;
}
.close-email-popup {
  top: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  span {
    font-size: 25px;
  }
}
.dropzone__input:focus {
  outline: none;
}
.spinner-border {
  z-index: 1;
}
.popup-uplodedfile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .file-img {
    vertical-align: middle;
  }
  .file-detail {
    width: 100%;
    max-width: calc(100% - 70px);
    word-break: break-word;
    padding-right: 10px;
    font-size: 15px;
  }
  .popup-viewdownload {
    font-size: 20px;
    color: #979ca7;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
}

.timeline-date {
  .timeline-date-info {
    vertical-align: top;
    width: 150px;
    cursor: default;
    @media (max-width: 1220px) {
      padding: 0 !important;
      padding-left: 15px !important;
    }

    span {
      width: 100%;
      height: 100%;
      display: block;
      padding-top: 15px !important;
      background-color: aliceblue;
      padding-left: 15px;
      cursor: default;
    }
  }
  .timeline-info-section {
    cursor: default !important;
    @media (max-width: 1220px) {
      padding: 0 !important;
      padding-left: 15px !important;
      padding-bottom: 3px !important;
      padding-right: 15px !important;
    }
    .timeline-info {
      background-color: #f5f5f5;
      display: block !important;
      cursor: default !important;
      td {
        padding: 0 !important;
      }
      span {
        display: block;
        cursor: default;
      }
    }
  }

  &:last-child {
    .timeline-info-section,
    .timeline-date-info {
      padding-bottom: 15px !important;
    }
  }
  &:first-child {
    .timeline-info-section,
    .timeline-date-info {
      padding-top: 15px !important;
    }
  }
}

.date-time-timeline {
  .react-datepicker-wrapper {
    max-width: unset !important;
    width: 100%;
    .react-datepicker__input-container {
      max-width: unset !important;
      width: 100%;
    }
  }
  span.date_icon {
    z-index: 4;
  }
}

span#clean {
  font-size: 22px;
}

.theme-light .card-body {
  padding: 30px !important;
}

.boxshadow-none {
  box-shadow: none !important;
}

.popup-btn-primary {
  padding: 8px 20px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 16.8px !important;
}

.link_text-orange {
  color: #ec6b1c;
}
.Agent-Reports-orange {
  border: 1px solid #ffe1cb !important;
  background-color: #fffaf5 !important;
}

.modal-content {
  border-radius: 5px !important;
}

.btn-gray {
  box-shadow: 0 0 1px 0 #ddd, 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
}
.tabs_nav,
.tab-content {
  border-radius: 5px;
}
.fix-width {
  width: 130px;
}

.debtor-detail {
  @media (max-width: 991px) {
    top: 0;
  }
}

.preview-report {
  border: 1px solid #e1e5eb;
  padding: 30px;
  border-radius: 5px;
  margin-top: 40px;
}

.preview-repor-file {
  margin: 0 !important;
  padding: 30px;
  border: 1px dashed #e1e5eb;
  border-radius: 5px;
  margin-top: 30px !important;
  padding-bottom: 15px;
  padding-right: 15px;
}

.tabs_nav {
  @media (max-width: 576px) {
    ul,
    .nav-tabs {
      width: 100%;
    }
  }
}
.top-24 {
  position: relative;
}
.eye-right {
  right: 0;
  background-color: transparent !important;
  border: none !important;
}

.checkbox-label {
  line-height: 20px !important;
}
.popup_tabs_nav {
  .tabs_nav {
    margin-bottom: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .nav-item {
    cursor: pointer;
    .nav-link {
      height: 50px !important;
      // border-bottom: 0 !important;
      margin: -1px;
      margin-bottom: 0;
    }
    :hover {
      border-color: transparent !important;
    }
    .active:hover {
      border-color: #ec6b1c !important;
    }
  }
  .tab-content {
    border: 1px solid #ec6b1c;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 20px;
    background-color: #fff;
    text-align: left;
    .repeat_tab {
      margin-top: 25px;
      border-top: 1px solid #7b7d7e2b;
      padding-top: 25px;
      .react-select__input {
        input {
          height: 30px;
        }
      }
    }
    div.repeat_tab:nth-child(1) {
      margin-top: 0 !important;
      border-top: 0 !important;
      padding-top: 0 !important;
    }
  }
}

.address_history_section {
  .address_history {
    margin-top: 25px;
    border-top: 1px solid #7b7d7e2b;
    padding-top: 25px;
  }
  .address_history:nth-child(1) {
    margin-top: 0 !important;
    border-top: 0 !important;
    padding-top: 0 !important;
  }
}

.form input,
.form textarea {
  height: 34px !important;
}
#contacts_accordion .react-datepicker-wrapper input,
.modal .react-datepicker-wrapper input {
  height: 34px !important;
}

.address_history_container {
  height: 275px;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 5px;
}
.modal-content .popup_tabs_nav .tab-content {
  height: 610px;
  overflow: auto;
  overflow-x: hidden;
}
.address_history_container::-webkit-scrollbar,
.modal-content .popup_tabs_nav .tab-content::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.address_history_container::-webkit-scrollbar-thumb,
.modal-content .popup_tabs_nav .tab-content::-webkit-scrollbar-thumb {
  background-color: #a6abb9;
}
.address_history_container::-webkit-scrollbar-track,
.modal-content .popup_tabs_nav .tab-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
