.taskBoard {
    display: flex;
    gap: 20px;

    margin-top: 20px;
    width: 100%;
    min-height: 100%;
    background-color: white;
    overflow-x: auto;
    padding: 27px 30px;
    min-height: 520px;
    overflow-y: auto;
}