.app-button {
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  transition: all 0.4s;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  border: none;
  font-size: 18px;
  line-height: 25.2px;
  font-family: var(--SourceSansPro) !important;
  font-weight: 600;

  .icon {
    width: auto;
    height: 20px !important;
    max-height: 20px !important;
  }

  &.small {
    padding: 5px 10px !important;
    height: 33px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.success {
    background-color: #2ca731;
    color: white;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: #24ca2a;
      background-color: #24ca2a !important;
    }
  }

  &.error {
    background-color: #f52656;
    color: white;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: #e20035;
      background-color: #e20035 !important;
    }
  }

  &.primary {
    background-color: #e3702a;
    color: white;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: #eb5c03;
      background-color: #eb5c03 !important;
    }
  }

  &.transparent {
    background-color: transparent;
    color: white;
    box-shadow: none !important;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: rgba($color: #000000, $alpha: 0.08);
      background-color: rgba($color: #000000, $alpha: 0.08) !important;
    }
  }

  &.secondary {
    background-color: #e1e1dd;
    color: black;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: #d0d0bb;
      background-color: #d0d0bb !important;
    }
  }

  &.loading {
    min-width: 120px;
  }
}
