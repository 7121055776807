html {
  box-sizing: border-box;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.loader{
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

*, *:before, *:after {
  box-sizing: inherit;
}
