.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formRow {
  display: flex;
  align-items: center;
  gap: 16px;
}

.distributionSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.distribution {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: black;
}