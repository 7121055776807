.modalWrapper {
  height: fit-content;
  width: fit-content;
  padding: 45px;
}

.layout{
  z-index: 1000;
}

.listWrapper{
  display: flex;
  flex-direction: column;
  gap: 5px;

  list-style: none;
  padding: 0;
  position: relative;
  li {
    b {
      font-size: 16px;
    }

    a {
      margin-left: 5px;
      text-decoration: underline;
      color: #ff6b00;
    }
  }
}

.closeButton {
  position: absolute;
  top: -40px;
  right: -40px;
  width: 32px;
  height: 32px;
  padding: 6px;
  span {
    font-size: 18px;
  }
}