.navigationButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  text-wrap: nowrap;
  font-size: 18px;
  line-height: 24px;
  color: #6b7280;
  

  background-color: transparent;
  cursor: pointer;

  border-radius: 6px;
  border: 0;

  width: 100%;
  min-width: 24px;
  height: 40px;
  padding: 8px;

  &:hover {
    background-color: #f3f4f6;
    color: black;
  }

  &.active {
    background-color: #f3f4f6;
    color: black;
  }
}

.labelHidden {
  transition: all 0s ease 0.2s;
  opacity: 0;
}


.navigationButtonContent {
  display: flex;
  align-items: center;
  gap: 10px;
}


.navigationButtonWrapper {
  min-width: 24px;
  height: 24px;
}