.wrapper {
  display: flex;

  cursor: pointer;

  gap: 11px;
  justify-content: flex-start;
  align-items: center;

  position: relative;

  padding: 7px 10px;

  border-radius: 4px;
  border: 1px solid #cfcfcf;

  background: #fff;

  box-shadow: 0px 2px 10px 0px rgba(12, 38, 56, 0.1);

  color: #0c2638;

  & > span:nth-child(2) {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; /* 131.25% */
  }

  .dropdown {
    cursor: auto;

    z-index: 100;
    top: 120%;
    left: 0;
    position: absolute;
    display: flex;

    width: 100%;

    flex-direction: column;

    border-radius: 6px;
    border: 1px solid #d8d8d8;

    background: var(--white, #fff);
    /* lg */
    box-shadow:
      0px 4px 6px -2px rgba(0, 0, 0, 0.05),
      0px 10px 15px -3px rgba(0, 0, 0, 0.1);

    .option {
      width: 100%;

      max-height: 200px;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      padding: 5px 0px;

      & > div:nth-child(1) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 18px;

        gap: 9px;

        & > span:nth-child(2) {
          color: #0c2638;

          font-family: Source Sans Pro;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
        }
      }

      cursor: pointer;
      .innerOption {
        display: flex;

        gap: 12px;

        padding: 8px;

        background: rgba(213, 214, 214, 0.4);

        div {
          display: flex;
          gap: 12px;

          & > span:nth-child(2) {
            color: #0c2638;

            font-family: Source Sans Pro;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
      .withChildren {
        position: relative;
        flex-direction: column;
        .secondInnerContainer {
          position: relative;
          display: flex;
          flex-direction: column;
          .secondInnerOption {
            position: relative;
            display: flex;
            flex-direction: column;
            padding-left: 12px;
          }
          &.active {
            border-bottom: 1px dashed black;
          }
        }
      }
    }
  }
}
.withOutChildren {
  background: transparent;
  box-shadow: none;
  border: 0;

  & > .dropdown {
    top: 40%;
  }
}
