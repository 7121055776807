$inputBackgroundColor: #f9fafb;
$inputBorderColor: #d1d5db;

.formTextAreaWrapper {
  position: relative;
  width: 100%;
}

.labelWrapper {
  color: black;
  margin-bottom: 8px;
  text-wrap: nowrap;
}

.label {
  margin: 0;
  margin-right: 8px;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}

.error {
  margin: 0;
  color: #ef4444;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}

.formTextArea {
  width: 100%;
  outline: none;
  background-color: $inputBackgroundColor;
  border: 1px solid $inputBorderColor;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #6B7280;
  border-radius: 16px;
  padding: 16px;
  height: 110px;
  resize: none;


  &.outlined {
    background-color: white;
  }
}