.paginationArrowsWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 36px;
}

.paginatonButton {
  width: 36px;
  height: 36px;

  justify-content: center;

  svg {
    min-width: 28px;
    height: 28px;
  }
  
  &.disabled {
    cursor: auto;
    color: #6b7280;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      color: #6b7280;
    }
  }
}

.right {
  transform: rotate(180deg);
}

.paginationInformation {
  text-wrap: nowrap;
  color: #6b7280;
  min-width: 120px;

  span {
    color: black;
    font-weight: bold;
    margin: 0 4px;
  }
}