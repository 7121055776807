.wipOverviewWrapper {
  display: flex;
  gap: 12px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 12px;
  
  flex: 1 1 25%;
  min-width: 340px;
}

.rightSide {
  display: flex;
  flex-direction: column;
  gap: 12px;

  flex: 1 1 75%;
}