.sectionWrapper {
  background-color: white;
  padding: 16px;
  width: 100%;
  height: fit-content;
  border-radius: 16px;
  box-shadow: 0px 1px 3px 0px #0000001A;
  box-shadow: 0px 1px 2px 0px #0000000F;

  &.takeFullHeight {
    height: 100%;
  }

  &.p0 {
    padding: 0;
  }
}