.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: max-content;
  padding: 40px 50px;
}

.labelWrapper {
  &>span:nth-child(1) {
    color: #0c2638;

    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .excludedCalendar {
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 100%;
  }
}