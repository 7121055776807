.inboxEmailHeaderWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 16px 16px 5px;
  flex: 0 0 72px;
  border-bottom: 1px solid #ebedf0;
}

.leftSideHeaderWrapper{
  display: flex;
  align-items: center;
  gap: 15px;
}

.actionsHeaderWrapper {
  display: flex;
  gap: 10px;
}


.separator {
  width: 2px;
  height: 40px;
  background-color: #ebedf0 ;
}

.composeButton {
  background-color: #1d4ed8;
  color: white;
  padding: 10px 20px;

  font-size: 14px;
  font-weight: 600;

  &:hover{
    background-color: #1d40b0;
    color: white;
  }

  svg {
    min-width: 20px ;
    height: 20px;
  }
}

.smallSquareButton {
  width: 32px;
  height: 32px;
  padding: 4px;

}

.squareButton {
  width: 36px;
  height: 36px;
  justify-content: center;

  svg {
    max-width: 28px;
    height: 28px;
  }
}

.search {
  width: 100%;
  height: 36px;
  outline: none;
  border: 1px solid #ebedf0;
  padding-left: 10px;
  border-radius: 20px;
}