$backgroundColor: #4B5563;
$disabledBackgroundColor: #D1D5DB;
$secondaryBackgroundColor: #E5E7EB;
$secondaryFontColor: #111928;
$alertBackgroundColor: #DC2626;
$alertFontColor: white;
$highlightedBackgroundColor: #FF6B03;
$highlightedFontColor: white;

.submitButton {
  display: flex;
  align-items: center;
  gap: 8px;

  background-color: #4B5563;

  color: white;
  font-weight: 600;

  text-wrap: nowrap;

  border-radius: 8px;
  border: 0;
  width: fit-content;

  &:hover {
    color: white;
  }

  &.secondary {
    background-color: $secondaryBackgroundColor;
    color: $secondaryFontColor;
  }

  &.highlighted {
    background-color: $highlightedBackgroundColor;
    color: $highlightedFontColor;
  }

  &.alert {
    background-color: $alertBackgroundColor;
    color: $alertFontColor;
  }

  &.disabled {
    background-color: $disabledBackgroundColor;
  }

  &.small {
    padding: 8px 16px;
    font-size: 12px;
    line-height: 18px;
  }

  &.medium {
    padding: 10px 20px;
    font-size: 14px;
    line-height: 21px;
  }

  &.large {
    padding: 12px 24px;
    font-size: 14px;
    line-height: 22px;
  }
}