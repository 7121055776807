$pagination-max-width: calc(calc(32px * 9) + calc(5px * 8));
$borderColor: #D1D5DB;
$fontColor: #6B7280;
$activeColor: #1A56DB;
$hoverColor: #dbeafe;

.paginationWrapper {
  display: flex;
  height: 32px;
  width: fit-content;
  border: 1px solid $borderColor;
  border-radius: 5px;
  overflow: hidden;

  :not(:last-child) {
    border-right: 1px solid $borderColor;
  }
}

.itemWrapper {
  user-select: none;
  color: $fontColor;
  display: flex;
  justify-content: center;
  background-color: transparent;
  align-items: center;
  border: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.paginationItemWrapper {
  background-color: white;

  &:hover {
    background-color: $hoverColor;
  }

  &.active {
    color: $activeColor;
  }
}

.paginationActionWrapper {
  width: 40px;
  height: 32px;
  &:hover {
    background-color: $hoverColor;
  }
  svg {
    width: 24px;
    height: 24px;
  }

  &.disabled {
    cursor: auto;

    &:hover {
      background-color: white;
    }
  }
}

.rightArrow {
  transform: rotate(180deg);
}



.paginationItemGapWrapper {
  cursor: auto;
}