.subTaskItem {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 250px;
  overflow-x: scroll;

  overflow-x: scroll;

  p {
    text-wrap: nowrap;
  }
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}