@import "~react-datepicker/dist/react-datepicker.css";
@import "~rc-time-picker/assets/index.css";

.settings-mangement {
  .mg_tools_box {
    cursor: pointer;
    border: 1px solid #dadada;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 26px 18px;
    flex-wrap: wrap;

    margin-bottom: 16px;
    &.setting_section {
      .mg_icon {
        max-width: 43px;
        max-height: 41px;
        margin-right: 15px;
        @include mediaq(1300px) {
          max-width: 33px;
          max-height: 31px;
        }
        @include mediaq(1100px) {
          max-width: 23px;
          max-height: 21px;
          margin-right: 0px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      flex-wrap: unset;
      justify-content: center;
      .mg_title {
        margin-left: 15px;
        margin-top: 0;
        width: auto;
        @include mediaq(1300px) {
          width: auto;
          font-size: 15px;
        }
      }
    }
    @include mediaq(1300px) {
      padding: 20px;
    }
    @include mediaq(991px) {
      margin-bottom: 15px;
    }
    .mg_icon {
      width: 64px;
      height: 64px;
      font-size: 30px;
      position: relative;
      border-radius: 100%;
      background-color: #f2f4f7;
      display: flex;
      justify-content: center;
      align-items: center;
      &.no_bg {
        background-color: unset;
      }
      img {
        max-width: 43px;
        max-height: 41px;
      }
      @include mediaq(1300px) {
        width: 50px;
        height: 50px;
        img {
          max-width: 33px;
          max-height: 31px;
        }
      }
      @include mediaq(1100px) {
        width: 40px;
        height: 40px;
        font-size: 20px;
        img {
          max-width: 23px;
          max-height: 21px;
        }
      }
      &.lnr-users {
        // &:before{
        // 	vertical-align: middle;
        // 	line-height: 1.4;
        // 	top: 50%;
        // 	left: 50%;
        // 	transform: translate(-50%,-50%);
        // 	position: absolute;
        // }
      }
    }
    .mg_title {
      margin-left: 15px;
      margin-top: 0;
      width: calc(100% - 100px);
      @include mediaq(1300px) {
        width: calc(100% - 75px);
        font-size: 15px;
      }
    }
  }
  .toolbox_divider {
    hr {
      border-top: 2px solid #dadada;
      // margin: 25px 0;
      margin-bottom: 25px;
    }
  }
  h5 {
    font-weight: 400;
    color: #979ca7;
    letter-spacing: 1px;
  }
}
#notes-tab {
  .custom-switch {
    display: inline-block;
    margin-right: 15px;
    width: 17%;
    margin-bottom: 5px;
    @include mediaq(1199px) {
      width: 30%;
    }
    @include mediaq(767px) {
      width: 100%;
    }

    &:nth-child(3n + 3) {
      @include mediaq(991px) {
        margin-right: 0;
      }
    }
  }
  .radio-btn__label {
    padding-left: 7px;
    margin-top: 0;
  }
  .material-table__toolbar-wrap {
    top: -7px;
  }
}
.client_info-block {
  .btn-primary {
    padding: 8px 10px;
    font-size: 15px;
    line-height: normal;
    margin-bottom: 0;
  }
  .btn-secondary {
    padding: 8px 10px;
    margin-bottom: 0;
  }
  .custom-contact-btn {
    display: flex;
    align-items: center;
    margin-top: 12px;
  }
  .tabs_nav {
    .nav-tabs {
      .nav-item {
        cursor: pointer;
      }
    }
  }
}
.tools_radio_btn {
  .form__form-group-field {
    label {
      &:first-child {
        margin-right: 15px;
      }
      .radio-btn__label {
        margin-top: 0;
      }
    }
  }
}
.add_new_propery {
  .card-header {
    background-color: #fff;
    a.card-link {
      padding-top: 10px;
      padding-bottom: 10px;
      color: #0c2638;
      display: flex;
      text-transform: uppercase;
      align-items: center;
    }
  }
}

.align-right {
  text-align: right;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .settings-mangement .col-lg-3 {
    flex: 0 0 33%;
    max-width: 33%;
  }
  // .settings-mangement .col-lg-3:last-child{
  // 	margin-top: 15px;
  // }
}
.MuiTableCell-root {
  font-family: var(--SourceSansPro) !important;
  font-size: 16px !important;
  min-width: 100px;
  @media (max-width: 1220px) {
    padding: 10px 8px !important;
  }
}
.MuiTableCell-head {
  font-weight: 600 !important;
}

@media (max-width: 767px) {
  .MuiTableCell-root {
    font-size: 14px !important;
  }
}
