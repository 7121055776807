$inputBackgroundColor: #f9fafb;
$inputBorderColor: #d1d5db;

.datePickerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.datePicker {
  input {
    width: 100%;
    outline: none;
    background-color: $inputBackgroundColor;
    border: 1px solid $inputBorderColor;
    font-size: 14px;
    line-height: 18px;
    border-radius: 8px;
  }

  &.small {
    input {
      padding: 8px 8px 8px 32px;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.medium {
    input {
      padding: 12px 12px 12px 46px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.large {
    input {
      padding: 16px 16px 16px 32px;
      font-size: 18px;
      line-height: 20px;
    }
  }

  &.outlined {
    input {
      background-color: white;
    }
  }
}

.calendarIcon {
  width: 20.5px;
  height: 20.5px;

  &.small {
    width: 20px;
    height: 20px;
    padding: 10px !important;
  }

  &.medium {
    width: 22px;
    height: 22px;
    padding: 12px !important;
  }


  &.large {
    width: 24px;
    height: 24px;
    padding: 14px !important;
  }
}

.labelWrapper {
  color: black;
  margin-bottom: 8px;
  text-wrap: nowrap;
}

.label {
  margin: 0;
  margin-right: 8px;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}

.error {
  margin: 0;
  color: #ef4444;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}