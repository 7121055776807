
$base-color: #ddd;

.skeletonWrapper {
  display: flex;
  flex-direction: column;
  margin: -20px;
  height: calc(100vh - 70px);
}

.skeletonHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 73px;

  padding: 16px;
  border-bottom: 1px solid #ebedf0;
}

.headerSideWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.skeletonBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 auto;
  padding: 16px;
}

.contactInformationWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  gap: 10px;
}

.emailWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}



.skeletonFooter {
  display: flex;
  align-items: center;
  gap: 10px;

  flex: 0 0 73px;
  padding: 16px;
  border-top: 1px solid #ebedf0;
}


.separator {
  width: 1px;
  height: 40px;
  background-color: #ebedf0;
}

.line {
  float: left;
  width: 100%;
  height: 26px;
  border-radius: 2px;
  background-color: $base-color;

  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  &.square {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }

  &.circle {
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  &.name {
    height: 14px;
    width: 100px;
  }

  &.email {
    height: 14px;
    width: 120px;
  }

  &.w60 {
    width: 60%;
  }

  &.w40 {
    width: 40%;
  }

  &.w30 {
    width: 30%;
  }

  &.subject {
    width: 80%;
  }

  &.bodyContent {
    height: 60px;
  }

  &.replyButton {
    border-radius: 6px;
    width: 95px;
    height: 40px;
  }

  &.forwardButton {
    border-radius: 6px;
    width: 120px;
    height: 40px;
  }

}


@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}