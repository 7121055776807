.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8d8e4;
  cursor: pointer;

  z-index: 100;
  border-radius: 50%;

  width: 27px;
  height: 27px;

  .img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(0.9);
  }
  position: relative;

  &::after {
    display: none;
    content: attr(data-tooltip);
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 40px;
    padding: 10px;
    transform: translate(-105%, -20%);
    font-size: 16px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  &:hover {
    &::after {
      display: block;
    }
  }
}
