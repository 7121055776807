.wrapper {
  display: flex;
  position: absolute;

  width: 200px;
  height: 200px;
  max-height: 200px;

  overflow-y: auto;

  background-color: white;
  z-index: 150;
  flex-direction: column;
  gap: 10px;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;

    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
  }

  .active {
    border: 1px solid green;
  }
}
