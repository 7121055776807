@import '~react-datepicker/dist/react-datepicker.css';
@import '~rc-time-picker/assets/index.css';

.form {
  display: flex;
  flex-wrap: wrap;
  @include directify($directions) {
    text-align: directed('left');
    direction: directed('direction');
  }

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 16.8px;
    border-radius: 5px;
    height: 32px;
    transition: border 0.3s;
    background: $fieldBackground;
    font-family: var(--SourceSansPro);
    font-weight: 400;

    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    @include themify($themes) {
      border: 1px solid themed('inputFieldBorder');
      color: themed('colorText1');
    }

    &[disabled] {
      @include themify($themes) {
        background: themed('colorBackgroundBody');
      }

      &:focus,
      &:active {
        @include themify($themes) {
          border-color: themed('colorBorder');
        }
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $color-accent;
    }
    @include mediaq(767px) {
      font-size: 11px;
      line-height: 13.2px;
    }
  }

  textarea {
    min-height: 85px;
  }

  &.form--horizontal {
    .form__form-group {
      display: flex;
      flex-wrap: wrap;
    }
    .form__form-group-modal {
      display: flex;
      flex-wrap: wrap;
    }

    .form__form-group-field {
      width: calc(100% - 80px);
      @include directify($directions) {
        #{directed('padding-left')}: 10px;
        #{directed('margin-left')}: 80px;
      }
    }

    .form__form-group-field-modal {
      width: 48%;
      left: 0px;
      position: relative;
    }

    .form__form-group-label {
      width: 80px;
      max-height: 32px;
      line-height: 18px;
      margin: auto 0;

      .required-label {
        color: '#ff0000';
      }

      & + .form__form-group-field {
        @include directify($directions) {
          #{directed('margin-left')}: 0px;
        }
      }
    }

    .form__form-group-description {
      @include directify($directions) {
        #{directed('margin-left')}: 90px;
      }
    }

    .form__button-toolbar {
      @include directify($directions) {
        #{directed('margin-left')}: 0px;
      }
    }

    .form__form-group-input-wrap--error-above {
      margin-bottom: 15px;

      .form__form-group-error {
        top: -28px;
      }
    }

    @media screen and (min-width: 480px) {
      .form__form-group-label {
        width: 120px;
      }

      .form__form-group-field {
        width: calc(100% - 120px);
        @include directify($directions) {
          #{directed('margin-left')}: 120px;
          #{directed('padding-left')}: 20px;
        }
      }

      .form__form-group-description,
      .form__button-toolbar {
        @include directify($directions) {
          #{directed('margin-left')}: 140px;
        }
      }
    }
  }

  &.form--preview {
    display: flex;

    & > div:nth-child(2) {
      @include directify($directions) {
        #{directed('margin-right')}: 50px;
      }
    }

    .form__form-group {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &.form--justify {
    display: flex;
    flex-wrap: wrap;

    .form__form-group {
      width: 33.3333%;
    }

    .form__button-toolbar {
      width: 100%;
    }
  }
}

.form__half {
  width: calc(50% - 15px);
  height: 100%;

  &:first-child {
    @include directify($directions) {
      #{directed('margin-right')}: 30px;
    }
  }

  .form__button-toolbar {
    float: right;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    @include directify($directions) {
      #{directed('margin-right')}: 0px;
    }
  }
}

.form__form-group {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.form__form-group--address {
  input:last-child {
    @include directify($directions) {
      #{directed('margin-left')}: 15px;
    }
    width: 70%;
  }
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
  @include directify($directions) {
    direction: #{directed('direction')};
  }
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;
  font-size: 16px;
  line-height: 21px;
  font-family: var(--SourceSansPro);
  font-weight: 400;

  .required-label {
    color: '#ff0000';
  }

  @include themify($themes) {
    color: themed('fieldTitle');
  }

  span {
    color: #dddddd;
  }
  @include mediaq(767px) {
    font-size: 14px;
    line-height: 16.8px;
  }
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;

  @include themify($themes) {
    background: themed('colorFieldsBorder');
    border: 1px solid themed('colorFieldsBorder');
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $color-accent;
    border: 1px solid $color-accent;

    svg {
      fill: #ffffff;
    }
  }
}

.form__form-group-file {
  label {
    border-radius: 2px;
    line-height: 18px;
    font-size: 12px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('colorText');
    }

    &:hover {
      @include themify($themes) {
        background: themed('colorFieldsBorder');
      }
    }
  }

  span {
    @include directify($directions) {
      #{directed('padding-left')}: 10px;
    }
  }

  input {
    display: none;
  }
}

.form__form-group-icon {
  padding: 6px;
  height: 32px;

  @include themify($themes) {
    background: themed('colorFieldsBorder');
    border: 1px solid themed('colorFieldsBorder');
  }

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.form__form-group-description {
  font-size: 10px;
  color: $color-additional;
  line-height: 13px;
  margin-top: 2px;
}

.form__button-toolbar {
  margin-top: 10px;
  right: 0;
  @include directify($directions) {
    direction: #{directed('direction')};
  }
}

.form__form-group-input-wrap {
  width: 100%;
}

.form__form-group-error {
  font-size: 12px;
  line-height: 13px;
  color: $color-error;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form__form-group-input-wrap--error-above {
  .form__form-group-error {
    position: absolute;
    margin: 0;
    @include directify($directions) {
      #{directed('right')}: 0px;
    }
    top: 0;
    padding: 5px 10px;
    background: #ffbcbc;
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      @include directify($directions) {
        #{directed('right')}: 10px;
      }
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid #ffbcbc;
    }
  }
}

.form__form-group-date-cvc {
  display: flex;
  width: 100%;

  .form__form-group-date {
    width: 100%;
    @include directify($directions) {
      #{directed('margin-right')}: 20px;
    }
  }

  .form__form-group-cvc {
    max-width: 280px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-date {
      @include directify($directions) {
        #{directed('margin-right')}: 0px;
      }
    }

    .form__form-group-cvc {
      max-width: 100%;
    }
  }
}

.form__form-group-id-category {
  width: 100%;
  display: flex;

  .form__form-group-id {
    min-width: 100px;
    width: 40%;
    @include directify($directions) {
      #{directed('margin-right')}: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-id {
      @include directify($directions) {
        #{directed('margin-right')}: 0px;
      }
      width: 100%;
    }
  }
}

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }

  .form__form-group-price {
    @include directify($directions) {
      #{directed('margin-right')}: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-price {
      @include directify($directions) {
        #{directed('margin-right')}: 0px;
      }
    }
  }
}
.form.form--color-picker .form__form-group {
  @media screen and (max-width: 767px) {
    width: 100%;

    button {
      width: 100%;
    }
  }
}

.form--color-picker {
  @media screen and (max-width: 767px) {
    justify-content: space-between;
  }
}

.geosuggest {
  .geosuggest__input-wrapper {
    /*.geosuggest__input{
      display:block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      vertical-align: middle;
      background-color: rgba(224, 231, 255, 0.2);
      border: 1px solid #E0E7FF;
      border-radius: 0.25rem;
      &:focus{
        border-color: #ff5a16;
        outline: 0;
      }
    }*/
  }
  .geosuggest__suggests-wrapper {
    .geosuggest__suggests {
      list-style: none;
      padding: 0;
      list-style: none;
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-radius: 0 5px 5px 0;
      border-color: #e0e7ff;
      .geosuggest__item {
        font-size: 1rem;
        padding: 0.5em 0.65em;
        cursor: pointer;
        &:focus {
          background: #f5f5f5;
        }
      }
      .geosuggest__item--active {
        background: #267dc0;
        color: #fff;
      }
    }
  }
  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }
}

// contact page
.slide_drop_down {
  cursor: pointer;
  box-shadow: 0 2px 10px 0 #ddd, 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  &.active {
    svg {
      transition: 0.2s ease-in;
      transform: rotate(180deg);
    }
  }
}
.hide_contact {
  max-height: 0;
  /*overflow: hidden;*/
  display: none;
  transition: 0.5s ease-out;
  &.active {
    max-height: unset;
    display: block;
  }
}
