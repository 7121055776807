.filterWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 16px;
}

.searchFieldWrapper {
  width: 400px;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}