@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap');
// font-family: 'Source Sans Pro', sans-serif;
@font-face {
  font-family: 'BaskervilleBold';
  //src: url("./fonts/BaskervilleBold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}
:root {
  --BaskervilleBold: 'BaskervilleBold';
  --SourceSansPro: 'Source Sans Pro', sans-serif;
}

//And This is for the color of the text ↓
.MuiTab-textColorPrimary.Mui-selected {
  color: #d97d54 !important;
  border: 1px solid #d97d54 !important;

  transition: border 1s ease-in-out;
}
.MuiTab-textColorPrimary {
  text-transform: none !important;
}

//this is for changing the span or bottom border ↓
.PrivateTabIndicator-colorPrimary-4 {
  background-color: var(--darkGreen) !important;
}

body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: var(--SourceSansPro);
  font-size: 14px;
  line-height: 16.8px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 400;
  margin-bottom: 0;

  @include themify($themes) {
    color: themed('colorText');
  }
}

p {
  margin: 10px 0 0 0;

  &:first-child {
    margin: 0;
  }
}

a {
  font-size: 17.5px;
  line-height: 21px;
  @include mediaq(767px) {
    font-size: 14px;
    line-height: 16.8px;
  }
}

h1 {
  font-size: 35px;
  line-height: 42px;
  font-family: var(--BaskervilleBold);
  &.subhead {
    font-size: 30px;
    color: $color-additional;
    line-height: 36px;
  }
  @include mediaq(767px) {
    font-size: 28px;
    line-height: 33.6px;
  }
}

h2 {
  font-size: 28px;
  line-height: 33.6px;
  font-family: var(--BaskervilleBold);
  @include themify($themes) {
    color: themed('fieldTitle');
  }

  &.subhead {
    font-size: 24px;
    color: $color-additional;
    line-height: 32px;
  }
  @include mediaq(767px) {
    font-size: 22.5px;
    line-height: 27px;
  }
}

h3 {
  font-size: 21px;
  line-height: 25.2px;
  font-family: var(--SourceSansPro) !important;
  font-weight: 700;
  &.subhead {
    font-size: 18px;
    color: $color-additional;
    line-height: 24px;
  }
  @include mediaq(767px) {
    font-size: 17px;
    line-height: 20.4px;
  }
}

h4 {
  font-size: 17.5px;
  line-height: 21px;
  font-family: var(--SourceSansPro) !important;
  font-weight: 600;
  &.subhead {
    font-size: 12px;
    color: $color-additional;
    line-height: 16px;
  }
  @include mediaq(767px) {
    font-size: 14px;
    line-height: 16.8px;
  }
}

h5 {
  font-size: 14px;
  line-height: 18px;

  &.subhead {
    font-size: 10px;
    color: $color-additional;
    line-height: 12px;
  }
}

h6 {
  font-size: 12px;
  line-height: 16px;

  &.subhead {
    font-size: 8px;
    color: $color-additional;
    line-height: 10px;
  }
}
label,
p {
  font-size: 17px;
  line-height: 21px;
}

.bold-text {
  @include directify($directions) {
    text-align: directed('left');
  }
  font-weight: 700;
}

.text-modal {
  font-weight: 700;
}

.typography--inline {
  display: flex;
  flex-wrap: wrap;

  * {
    margin-top: auto;
    @include directify($directions) {
      #{directed('margin-right')}: 15px;
    }
  }
}

.typography-card {
  @include directify($directions) {
    text-align: directed('left');
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
  }
}

blockquote {
  font-size: 13px;
  font-style: italic;
  @include directify($directions) {
    #{directed('border-left')}: 3px solid $color-accent;
  }
  margin-bottom: 0;
  @include directify($directions) {
    text-align: directed('left');
    #{directed('padding-left')}: 10px;
  }
}

.highlight {
  background-color: $color-accent;
  color: #ffffff;
}

.typography-message {
  @include directify($directions) {
    text-align: directed('left');
  }
}

.red-text {
  color: #ad4444;
}

.page-title {
  color: black !important;
  margin-bottom: 30px;
  a {
    margin-bottom: 30px;
    display: block;
    color: #646777;
  }
  @include directify($directions) {
    text-align: directed('left');
  }
  /*font-weight: 500;*/
  // text-transform: capitalize;
  /*font-size: 20px;*/

  &:last-child {
    margin-bottom: 30px;
  }

  &.page-title--not-last {
    margin-top: 20px;
  }
}

.page-subhead {
  @include directify($directions) {
    text-align: directed('left');
  }
  margin-bottom: 20px;

  &.subhead {
    font-size: 14px;
    opacity: 0.7;
  }
}

::selection {
  color: #ffffff;
  background: $color-accent;
}
table {
  th {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
  td {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    line-height: 20px;
  }
}
.pointer {
  cursor: pointer;
}
.linkText {
  color: $color-orange;
}
.uppercase {
  text-transform: uppercase;
}
.d_flex {
  display: flex;
  flex-wrap: wrap;
}
.flex_jusbt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.flex_aligncenter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.flex_center_jusbt {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
