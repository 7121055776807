@use 'src/scss/base/spacing.module.scss';

$borderColor: #E5E7EB;

.subSection {
  &.flex {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &.line-dashed {
    border-bottom: 1px dashed $borderColor;
  }

  &.line-solid {
    border-bottom: 1px solid $borderColor;
  }
}