.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 80vh;
  width: 80wv;
}

.row {
  display: flex;
  gap: 16px;
}

.buttonWrapper {
  display: flex;
  justify-content: end;
}

.inputWrapper {
  flex: 1 auto;

  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4px;

  & > div {
    display: flex;
    border-radius: 8px;
    height: 42px;
    border: 1px solid gray;
    justify-items: center;
    align-items: center;
    padding: 2px 4px;
  }

  & > span {
    font-weight: 600;
  }

  & > div > span {
    padding: 4px;
  }
}

.devider {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 15px;

  span {
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      display: flex;
      border: 1px dashed #969ba6;
      width: 45%;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(10%, 0);
    }

    &::before {
      content: '';
      display: flex;
      border: 1px dashed #969ba6;
      width: 45%;
      height: 1px;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(-10%, 0);
    }
  }
}

.actionWrapper {
  display: flex;
  justify-content: end;
  gap: 16px;
}

.selectWrapper {
  width: 100%;
}
