.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.headerText {
    color: #000;

    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}