.mb1 {
  margin-bottom: 4px !important;
}

.mb2 {
  margin-bottom: 8px !important;
}

.mb3 {
  margin-bottom: 12px !important;
}

.mb4 {
  margin-bottom: 16px !important;
}

.p1 {
  padding: 4px 0 !important;
}

.p2 {
  padding: 8px 0 !important;
}

.p3 {
  padding: 12px 0 !important;
}

.p4 {
  padding: 16px 0 !important;
}

.pb1 {
  padding-bottom: 4px !important;
}

.pb2 {
  padding-bottom: 8px !important;
}

.pb3 {
  padding-bottom: 12px !important;
}

.pb4 {
  padding-bottom: 16px !important;
}