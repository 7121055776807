.dateCell {
  color: #6B7280;
}

.debtorCell {
  font-weight: 700;
  color: #F3742B;
}

.trashIcon {
  min-width: 20px;
  height: 20px;
  cursor: pointer;
}