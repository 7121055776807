$inputBackgroundColor: #f9fafb;
$inputBorderColor: #d1d5db;

.formInputWrapper {
  position: relative;
  width: 100%;
}

.formInputIcon {
  position: absolute;


  &.small {
    width: 18px;
    height: 18px;
    left: 8px;
    bottom: 8px;
  }

  &.medium {
    width: 20px;
    height: 20px;
    left: 12px;
    bottom: 12px;
  }

  &.large {
    width: 20px;
    height: 20px;
    left: 16px;
    bottom: 16px;
  }
}

.inputChildrenWrapper {
  position: absolute;


  &.small {
    width: 18px;
    height: 18px;
    right: 8px;
    bottom: 8px;
  }

  &.medium {
    width: 20px;
    height: 20px;
    right: 12px;
    bottom: 12px;
  }

  &.large {
    width: 20px;
    height: 20px;
    right: 16px;
    bottom: 16px;
  }
}

.formInput {
  width: 100%;
  outline: none;
  padding: 8px;
  background-color: $inputBackgroundColor;
  border: 1px solid $inputBorderColor;
  font-size: 14px;

  border-radius: 8px;

  &.small {
    padding: 8px;
    font-size: 14px;
    line-height: 18px;

    &.icon {
      // 8 + 8 + 16
      padding-left: 32px;
    }
  }

  &.medium {
    padding: 12px;
    font-size: 16px;
    line-height: 20px;

    &.icon {
      // 12 + 12 + 20
      padding-left: 44px;
    }
  }

  &.large {
    padding: 16px;
    font-size: 18px;
    line-height: 20px;

    &.icon {
      // 16 + 16 + 20
      padding-left: 52px;
    }
  }

  &.outlined {
    background-color: white;
  }
}


.geoSuggestContainer {
  position: relative;
}

.getSuggestInput {
  position: relative;
  z-index: 3;
  @extend .formInput;
}

.geoSuggestList {
  position: absolute;
  z-index: 2;
  top: calc(46px - 8px);
  width: 100%;
  background-color: white;
  border-radius: 0 0 8px 8px !important;
  border: 1px solid $inputBorderColor !important;
  padding: 8px !important;
}

.geoSuggestListHidden {
  display: none;
}

.phoneInputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.phoneInput {
  background-color: $inputBackgroundColor !important;
  border: 1px solid $inputBorderColor !important;

  padding: 12px 12px 12px 48px !important;
  font-size: 16px !important;
  line-height: 20px !important;
  border-radius: 8px !important;
  width: 100% !important;
  height: fit-content !important;
}

// label

.labelWrapper {
  color: black;
  margin-bottom: 8px;
  text-wrap: nowrap;
}

.label {
  margin: 0;
  margin-right: 8px;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}

.error {
  margin: 0;
  color: #ef4444;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}