$mainIconColor: #1F2A37;

.speedDialWrapper {
  position: relative;
}

.speedDialOpenButton {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #F3742B;

  width: 56px;
  height: 56px;

  color: white;

  border-radius: 8px;

  &.active {
    outline: 3px solid #A4CAFE;
  }

  svg {
    color: white;
    width: 32px;
    height: 32px;
  }
}

.speedDialSelectList {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 10px 2px;

  position: absolute;

  &.top {
    left: 50%;
    top: 0;
    z-index: 9999;
    transform: translate(-50%, -100%);
  }

  &.bottom {
    left: 50%;
    bottom: 0;
    z-index: 9999;

    transform: translate(-50%, 100%);
  }
}

.speedDialSelect {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #E5E7EB;
  border-radius: 8px;

  color: $mainIconColor;

  width: 52px;
  height: 52px;

  box-shadow: 0px 1px 2px 0px #00000014;

  background-color: white;

  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    .speedDialSelectLabelWrapper {
      opacity: 1;
    }
  }
}

.speedDialSelectIcon {}

.speedDialSelectLabelWrapper {
  opacity: 0;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(-52px, -50%);

  padding: 4px 12px;
  background-color: $mainIconColor;

  transition: all 0.2s ease 0s;
  border-radius: 4px;
}

.speedDialSelectLabel {

  line-height: 21px;
  text-wrap: nowrap;
  color: white;
}


.triangleWrapper {
  position: absolute;
  right: -4px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}

.triangle {
  position: relative;
  background-color: $mainIconColor;
  text-align: left;
}

.triangle:before,
.triangle:after {
  content: '';
  position: absolute;
  background-color: inherit;
}

.triangle,
.triangle:before,
.triangle:after {
  width: 9px;
  height: 9px;
  border-top-right-radius: 30%;
}

.triangle {
  transform: rotate(-60deg) skewX(-30deg) scale(1, .866);
}

.triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, .707) translate(0, -50%);
}

.triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(.707, 1.414) translate(50%);
}