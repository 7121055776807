.form__form-group-field_file {
  width: calc(100% - 120px);
  position: relative;
}

.form__files {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
}

.form__form-group-field_file_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.file-input__input {
  width: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 100%;
  min-height: 100%;
}

.text {
  z-index: 1;
}

.file-input__label {
  min-width: 150px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #4245a8;
  font-size: 14px;
  padding: 10px 12px;
  border: 2px dotted #4245a8;
  height: 40px;
  transition: 300ms;
  margin-top: 20px;
  z-index: 1;
}

.file-input__label:hover {
  background-color: rgba(66, 69, 168, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.file-container-sing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-container-sing img {
  width: auto;
  height: 30px;
  margin-right: 2px;
}

.file-container-sing p {
  font-size: 12px;
  max-width: 50px;
  overflow: hidden;
  word-break: keep-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin-top: 0;
}

.required-label {
  color: '#ff0000';
}
