.sectionsWrapper {
  display: flex;
  gap: 16px;
}

.leftSections {
  display: flex;
  flex-direction: column;
  gap: 16px;

  min-width: 380px;
  width: 25%;
}

.viewClientButton {
  margin-top: 16px;
}