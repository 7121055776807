.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .filterSection {
    width: 100%;
    gap: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 20px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
  .filterButton {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    background: #fff;
    color: #0c2638;

    text-align: center;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; /* 131.25% */

    gap: 10px;
    box-shadow: 0px 2px 10px 0px rgba(12, 38, 56, 0.1);

    outline: none;
    border: 0;

    padding: 12px;

    &:hover {
      opacity: 0.5;
    }
  }
  .taskBoard {
    display: flex;
    width: 100%;
    min-height: 100%;
    overflow-x: auto;
    padding: 27px 30px;
    gap: 30px;
    min-height: 520px;
    overflow-y: auto;

    .empty {
      display: flex;
      width: 100%;
      min-height: 420px;
      justify-content: center;
      align-items: center;
    }
  }
}
