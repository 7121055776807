.arrowIcon {
  width: 16px;
  height: 16px;

  transition: all 0.3s ease 0s;

  &.active {
    transform: rotate(-180deg) !important;
  }

  &.hidden {
    display: none;
  }
}

.dropDownMenu {}

.dropDownSearch {
  width: 100%;
  height: 35px;
  margin: 5px 0;
  border-radius: 6px;
  border: 1px solid #e5e7eb;
  outline: none;

  padding-left: 15px;
}


.dropDownList {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0;

  height: 130px;
  overflow-y: scroll;
}

.dropDownItem {
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 24px;

  cursor: pointer;

  border-radius: 6px;

  list-style: none;

  width: 100%;
  height: 40px;
  padding: 8px 0 8px 15px;

  &:hover {
    background-color: #f3f4f6;
    color: black;
  }
}

.notFound {
  font-weight: bold;
  font-size: 16px;
}

.center {
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}