.subTaskIndicatorWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 18px;
    height: 18px;

    border-radius: 50%;

    &.notCompleted {
        border: 2.5px solid #9ca3af;
    }

    &.partiallyCompleted {
        border: 2.5px solid #ef4444;
    }

    &.fullyCompleted {
        border: 2.5px solid #16a34a;
    }

}

.subTaskIndicatorDot {
    width: 7px;
    height: 7px;

    border-radius: 50%;

    &.notCompleted {
        background-color: #9ca3af;
    }

    &.partiallyCompleted {
        background-color: #ef4444;
    }

    &.fullyCompleted {
        background-color: #16a34a;
    }
}