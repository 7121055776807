$inputBorderColor: #d1d5db;

.recoverableCostListWrapper {
  margin: 16px 0;
  max-height: calc(111px * 3);
  overflow-y: scroll;
}


.recoverableCostListWrapper::-webkit-scrollbar {
  width: 8px;
}

.recoverableCostListWrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.recoverableCostListWrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.totalRecoverableCostField {
  max-width: 180px;
}

.removeButton {
  background-color: transparent;
  border: none;
  padding: 0;
  padding-right: 8px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.inputWrapper {
  width: 100%;
}

.formInput {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  width: 100%;
  outline: none;
  padding: 8px;
  border: 1px solid $inputBorderColor;
  font-size: 14px;

  border-radius: 8px;

  padding: 12px;
  font-size: 16px;
  line-height: 20px;
}

.formWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.labelWrapper {
  color: black;
  margin-bottom: 8px;
  text-wrap: nowrap;
}

.label {
  margin: 0;
  margin-right: 8px;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}

.customCurrencyInput {
  padding: 0 !important;  
  border: 0;
  border-radius: 0;
  width: 100% !important;
  background-color: white;


  &.unenforceableCost {
    color: #646777 !important;
  }

  &.recoverableCost {
    color: #E02424 !important;
  }
}