.wrapper {
  position: relative;
  display: flex;
  gap: 10px;
  .filterButton {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    background: #fff;
    color: #0c2638;

    text-align: center;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; /* 131.25% */

    gap: 10px;
    box-shadow: 0px 2px 10px 0px rgba(12, 38, 56, 0.1);

    outline: none;
    border: 0;

    padding: 12px;

    &:hover {
      opacity: 0.5;
    }
  }
  .filterContent {
    .filter {
      width: 200px !important;
      position: absolute;
      top: 110%;
      left: -120%;
    }
    .isActive {
      left: 5%;
    }
  }
}
