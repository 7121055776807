.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 65px;

  background-color: #F1F5F9;
  border-radius: 10px;
  padding: 10px 25px;
}