.topBarWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  height: 70px;
  padding: 12px 20px 12px 12px;
  position: relative;
  background-color: white;

  border-bottom: 1px solid #ebedf0;
  z-index: 2;
}

.actions {
  display: flex;
  gap: 10px;
}

.displayInHeaderComponentWrapper {
  flex: 1 1 auto;
}