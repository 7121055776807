.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sectionsWrapper {
  display: flex;
  gap: 16px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 1 25%;
  min-width: 360px;
}

.rightSide {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 1 75%;
}

.highlighted {
  color: #F3742B;
}