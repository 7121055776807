/* @import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i'); */
/* @import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700'); */

/* @font-face {
    font-family: 'Baskerville';
    src: local('Baskerville'), url(./font/Baskerville-Bold.otf) format('otf');
} */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap');
 /* font-family: 'Source Sans Pro', sans-serif; */


html{
    font-size: 15px;
}

body{
  /* font-family: 'Montserrat', sans-serif; */
  font-weight: 400;
  color: #2E384D;
  /* font-size: 1rem; */
}
h1{
    font-size: 35px;
}
h1,h2,h3,h4,h5{
  /* font-family: 'Montserrat', sans-serif; */
  color: #2E384D;
}
img {
    max-width: 100%;
}
a,a:hover{
    color: #0052ff;
}

a.forgot_pass_link,.btn,
.custom_table tr {
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
}
.custom_table tr {
    position: relative;
}
.custom_table tr:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    width: 6px;
    height: 59px;
}
.custom_table tr.high_priority:before {
    background-color: #ff1744;
}
.custom_table tr.medium_priority:before {
    background-color: #42a5f5;
}
.custom_table tr.low_priority:before {
    background-color: #fbc02d;
}

.c_orange{
    color: #ec6b1c;
}
.c_light{
    color: #BFC5D2;
}
.fw-300 {
    font-weight: 300;
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700;
}
.fw-800 {
    font-weight: 800;
}
.fw-900 {
    font-weight: 900;
}
.bg_orange {
    background: #ec6b1c;
    color: #fff;
}
.bg_blue {
    background: #2e5bff;
    color: #fff;
}
.btn i {
    font-size: 1.2rem;
}
.btn {
    box-shadow: 0 1px 5px rgba(0,0,0,.10);
    -webkit-box-shadow: 0 1px 5px rgba(0,0,0,.10);
    -moz-box-shadow: 0 1px 5px rgba(0,0,0,.10);
    font-weight: 500;
}
/*.btn.bg_orange {
    box-shadow: 0 1px 5px rgba(0,0,0,.10);
    -webkit-box-shadow: 0 1px 5px rgba(0,0,0,.10);
    -moz-box-shadow: 0 1px 5px rgba(0,0,0,.10);
}*/
.btn.bg_blue {
    box-shadow: 0 2px 20px rgba(46, 91, 255,0.12);
    -webkit-box-shadow: 0 2px 20px rgba(46, 91, 255,0.12);
    -moz-box-shadow: 0 2px 20px rgba(46, 91, 255,0.12);
}

.btn:hover,
.btn.bg-white:hover{
    box-shadow: 0 2px 20px rgba(0,0,0,0.12);
    -webkit-box-shadow: 0 2px 20px rgba(0,0,0,0.12);
    -moz-box-shadow: 0 2px 20px rgba(0,0,0,0.12);
    color: #fff;
}
.btn.bg_orange:hover {
    color: #fff;
    box-shadow: 0 1px 5px rgba(0,0,0,.10);
    -webkit-box-shadow: 0 1px 5px rgba(0,0,0,.10);
    -moz-box-shadow: 0 1px 5px rgba(0,0,0,.10);
}
.btn.bg_blue:hover {
    background: #2e5bff;
    color: #fff;
    box-shadow: 0 2px 20px rgba(46, 91, 255,0.12);
    -webkit-box-shadow: 0 2px 20px rgba(46, 91, 255,0.12);
    -moz-box-shadow: 0 2px 20px rgba(46, 91, 255,0.12);
}


.form-control::-webkit-input-placeholder { 
  color: #2E384D;
  opacity: 1;
}
.form-control::-moz-placeholder { 
  color: #2E384D;
  opacity: 1;
}
.form-control:-ms-input-placeholder { 
  color: #2E384D;
  opacity: 1;
}
.form-control:-moz-placeholder { 
  color: #2E384D;
  opacity: 1;
}


/*Custom style*/

.sidebar .nav .nav-item .nav-link .menu-title {
    font-size: 1rem;
    font-weight: 500;
}

button.navbar-toggler.navbar-toggler span:before {
    color: #BFC5D2;
}
.sidebar ul.nav li.nav-item a.nav-link img {
    max-width: 23px;
    margin: 0 15px 0 0;
}
.sidebar ul.nav li.nav-item a.nav-link.active {

    background-color: #e8edff;
    border-left: 1px solid #ec6b1c;
}
.sidebar ul.nav li.nav-item a.nav-link.active span.menu-title{color:#2e5bff!important}
.sidebar ul.nav li.nav-item a.nav-link {
    padding: 18px 20px;
    color: #B0BAC9;
}
.input-group.search_wrap .input-group-prepend,
.input-group.search_wrap  input {
    height: 75px;
}
body .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group input.form-control {
    color: #BFC5D2;
    font-size: 18px;
    font-weight: 400;
    padding-left: 15px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group {
    padding: 0 15px 0 20px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item {
    margin-left: 28px!important;
    margin-right: 0!important;
}




@font-face {
  /* font-family: 'fontello';
  src: url('../fonts/icons/fontello.eot');
  src: url('../fonts/icons/fontello.eot') format('embedded-opentype'),
  url('../fonts/icons/fontello.woff2') format('woff2'),
  url('../fonts/icons/fontello.woff') format('woff'),
  url('../fonts/icons/fontello.ttf') format('truetype'),
  url('../fonts/icons/fontello.svg') format('svg');
  font-weight: normal;
  font-style: normal; */
}
[class^="c-icon-"]:before, [class*=" c-icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 
.c-icon-mail:before { content: '\e801'; } /* '' */
.c-icon-menu-icon:before { content: '\e803'; } /* '' */
.c-icon-notification:before { content: '\e804'; } /* '' */
.c-icon-refresh:before { content: '\e806'; } /* '' */
.c-icon-search:before { content: '\e807'; } /* '' */
.c-icon-settings:before { content: '\e808'; } /* '' */
.c-icon-dashboard:before { content: '\e80a'; } /* '' */
.c-icon-calnder:before { content: '\e80d'; } /* '' */
.c-icon-phone:before { content: '\e80e'; } /* '' */
.c-icon-group:before { content: '\e80f'; } /* '' */


@font-face {
  /* font-family: "Flaticon";
  src: url("../fonts/icons/Flaticon.eot");
  src: url("../fonts/icons/Flaticon.eot?#iefix") format("embedded-opentype"),
  url("../fonts/icons/Flaticon.woff2") format("woff2"),
  url("../fonts/icons/Flaticon.woff") format("woff"),
  url("../fonts/icons/Flaticon.ttf") format("truetype"),
  url("../fonts/icons/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal; */
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    /* src: url("../fonts/icons/Flaticon.svg#Flaticon") format("svg"); */
}
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-filter-filled-tool-symbol:before { content: "\f100"; }







.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown i {
    font-size: 1.5rem;
    color: #BFC5D2;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown i.c-icon-mail {
    font-size: 20px;
}
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-toggler {
    font-size: 16px;
}
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand {
    margin: 0 20px 0 0;
}
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand img {
    width: 225px;
}
.navbar {
    border-bottom: 1px solid #e4e8f0b3;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .item-thumbnail i {
    color: #fff;
    margin: 0;
}
.tabs_nav {
    margin: 0 0 30px;
    display: flex;
    box-shadow: 0 2px 10px 0 #ddd, 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}
.tabs_nav .nav-tabs .nav-item .nav-link, ul.c_right_nav_list li a {
    padding: 0 30px;
    height: 71px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid transparent;
    position: relative;
    font-weight: 500;
    color: #2E384D;
    font-size: 1rem;
    text-decoration: none;
}
.tabs_nav .nav-tabs .nav-item .nav-link:hover, 
ul.c_right_nav_list li a:hover{
    color: #ff8f00 !important;
}

ul.c_right_nav_list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: auto;
    display: flex;
}
ul.c_right_nav_list li {
    border-left: 1px solid rgba(46,91,255,0.08);
}
.tabs_nav,
.tab-content {
    border: 1px solid rgba(46,91,255,0.08);
}
.tabs_nav .nav-tabs .nav-item .nav-link.active:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    border-style: solid;
    border-color: transparent transparent #ec6b1c transparent;
    border-width: 0 5px 5px 5px;
    margin-left: -5px;
}
.tabs_nav .nav-tabs .nav-item {
    margin-bottom: -1px;
    border-right: 1px solid rgba(46,91,255,0.08);
}
.tabs_nav .nav-tabs {
    padding: 0;
    border: 0;
}

.tabs_nav .nav-tabs .nav-item .nav-link.active {
    border-color: #ec6b1c;
    color: #ec6b1c;
}


.managing_tools_block label.checkbox input {
    display: none;
}

.managing_tools_block label.checkbox {
    height: 110px;
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #f2f4fa;
    padding: 0 20px;
    border-radius: 5px;
    position: relative;
    margin: 0 0 30px;
    cursor: pointer;
}
.managing_tools_block label.checkbox span {
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    color: #2E384D;
    cursor: pointer;
    position: relative;
    padding-left: 80px;
}
.managing_tools_block label.checkbox span:before {
    display: inline-block;
    content: "";
    height: 65px;
    width: 65px;
    vertical-align: middle;
    margin-right: 0;
    background: #f0f4f7;
    border-radius: 50%;
    border: 1px solid #ecf0f6;
    text-align: center;
    font-size: 28px;
    padding: 17px 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.managing_tools_block label.checkbox input[type="checkbox"]:checked + span:before {
    border-color: #ec6b1c;
    content: "\f00c";
    font-family: fontAwesome;
    color: #fff;
    background: #ec6b1c;
}
form.managing_tools_block hr {
    border-top: 2px solid #f2f4fa;
    margin: 0 0 30px;
}
form.managing_tools_block h6 {
    font-weight: 600;
    color: #8798AD;
    letter-spacing: 0.05em;
    margin: 12px 0 20px;
}
form.managing_tools_block h6 + p {
    margin: 0 0 30px;
    font-weight: 500;
}


/*login page style
======================================================*/
.login_wrapper_inner {
    display: flex;
}
.login_wrapper_inner > .login_left {
    width: 48%;
    /* padding: 20px; */
}
.login_wrapper_inner > .login_right {
    width: 52%;
}
.login_form_wrap .login_form_inner {
    padding: 40px 0;
    max-width: 310px;
    margin: 0 auto;
    width: 100%;
}

.login_logo_div {
    padding: 50px 0;
    text-align: center;
    max-width: 200px;
    margin: 0 auto;
}
.login_form_wrap {
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    width: 100%;
}
.login_form_wrap input.form-control {
    border: 1px solid #E0E7FF;
    background: #f9faff;
    border-radius: 5px;
    font-size: 1rem;
    color: #2E384D;
}
.login_form_wrap input.form-control:focus{
    border: 1px solid #ff8f00 !important;
    box-shadow:none !important;
}
.login_head {
    margin: 0 0 45px;
}

.login_head h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
}
.login_head h2 {
    margin: 0 0 10px;
}
.login_head p {
    color: #8798AD;
    font-size: 14px;
    /* padding: 10px 0 0; */
}
.login_form_inner label {
    color: #B0BAC9;
    margin: 0 0 5px;
    font-size: 14px;
}
.login_form_inner .btn {
    width: 100%;
    display: block;
    /*height: 50px;*/
    font-weight: 500;
    font-size: 1rem;
    margin: 0 0 15px;
    padding: 8px 20px;
}
.login_form_inner form,
.login_form_inner form .form-control {
    /* font-family: 'Arial', 'Montserrat', sans-serif; */
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 0.02rem;
}

a.forgot_pass_link {
    font-family: 'Source Sans Pro', sans-serif;
    float: right;
    color: #B0BAC9;
    font-weight: 500;
}
a.forgot_pass_link:hover{
    color: #ec6b1c;
}

.login_right_banner {
    height: 100vh;
}
.login_right_banner img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link {
    width: 38px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .item-thumbnail {
    width: 2.25rem;
    height: 2.25rem;
}

/*Dashboard page style
==================================================================*/
.local_times ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.local_times ul li {
    display: inline-block;
    padding: 3px 20px;
    color: #b7becc;
}
.local_times {
    margin: -30px -30px 30px -60px;
    background: #f8f9fd;
    padding: 12px 15px;
    border-top: 1px solid rgba(46,91,255,0.08);
    border-bottom: 1px solid rgba(46,91,255,0.04);
    text-align: center;
}

.dashbaord_cards_list .card {
    border: 1px solid rgba(46,91,255,0.08);
    margin: 0 0 20px;
}
.dashbaord_cards_list{
    padding: 0 0 10px;
}
.dashbaord_cards_list .card .card-body {
    padding: 22px;
}
.dashbaord_cards_list a{
    text-decoration: none;
}
.card-head {
    color: #8798AD;
    font-weight: bold;
    font-size: 13px;
}
.large_text {
    color: #2E384D;
    font-size: 42px;
    font-weight: 500;
    margin: 6px 0;
}
.up_down_value {
    font-weight: 600;
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size: 10px auto;
    padding-left: 17px;
    display: inline-block;
}
.up_down_value.going_up {
    color: #2DB744;
    /* background-image: url(../images/up-arrow.png); */
}
.nav-tabs.tabs_style_btn .nav-item .nav-link {
    border: 0;
    background: #fff;
    padding: 10px 15px;
    font-size: 0.875rem;
    line-height: 1;
    font-weight: 500;
    box-shadow: 0 2px 20px rgba(0,0,0,0.12);
    border-radius: 5px;
    min-width: 132px;
    text-align: center;
    border: 2px solid transparent;
    color: #2E384D;
}
.nav-tabs.tabs_style_btn .nav-item {
    margin: 0 10px;
}
.nav-tabs.tabs_style_btn .nav-item:last-child{
    margin-right: 0px;
}
.nav-tabs.tabs_style_btn {
    border: 0;
}
.nav-tabs.tabs_style_btn .nav-item .nav-link.active {
    border-color: #2e5bff;
    color: #2E384D;
    font-weight: bold;
}
.custom_table {
    border: 0;
    display: block;
    min-width: 925px;
}
.custom_table a{
    text-decoration: none;
}
.custom_table > thead, .custom_table > tbody {
    display: block;
}
.custom_table > thead > tr:not(.edit_dropdown_div),
.custom_table > tbody > tr:not(.edit_dropdown_div) {
    display: flex;
    width: 100%;
    justify-content: stretch;
    margin: 0 0 10px;
    align-items: stretch;
}
.custom_table > thead > tr:not(.edit_dropdown_div):hover,
.custom_table > tbody > tr:not(.edit_dropdown_div):hover {
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    box-shadow: 0 8px 30px rgba(0,0,0,0.06);
    -webkit-box-shadow: 0 8px 30px rgba(0,0,0,0.06);
    -moz-box-shadow: 0 8px 30px rgba(0,0,0,0.06);
    -ms-box-shadow: 0 8px 30px rgba(0,0,0,0.06);
}
.custom_table > thead > tr:not(.edit_dropdown_div) th,
.custom_table > tbody > tr:not(.edit_dropdown_div) td{
    font-size: 11px;
}
.custom_table > thead > tr:not(.edit_dropdown_div) th {
    padding: 2px 10px;
    font-weight: 700;
    color: #BFC5D2;
    text-transform: uppercase;
}
.custom_table > tbody > tr:not(.edit_dropdown_div) td {
    background: #fff;
    padding: 0;
    border-top: 1px solid rgba(46,91,255,0.15);
    border-bottom: 1px solid rgba(46,91,255,0.15);
    padding: 0px 10px;
    min-height: 60px;
}
.t_l_date {
    width: 12%;
}
.t_l_time {
    width: 10%;
}
.t_l_type {
    width: 17%;
}
.t_l_client {
    width: 25%;
}
.t_l_staff {
    width: 10%;
}
.t_l_renew {
    width: 15%;
}
.t_l_title {
    width: 23%;
}
.t_l_props {
    width: 15%;
}
.t_l_action {
    width: 100px;
}
.t_l_work {
    width: 60px;
    text-align: center;
}
.t_l_wip {
    width: 10%;
}
.custom_table tr:not(.edit_dropdown_div) td.t_l_action > div,
.custom_table tr:not(.edit_dropdown_div) td.t_l_work > div {
    justify-content: center;
}

.custom_table > tbody > tr:not(.edit_dropdown_div) > td:first-child {
    border-left: 1px solid rgba(46,91,255,0.15);    
}
.custom_table > tbody > tr:not(.edit_dropdown_div) > td:last-child{
    border-right: 1px solid rgba(46,91,255,0.15);    
}
.custom_table > tbody > tr:not(.edit_dropdown_div) > td > div {
    background: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
}
.tab-content {
    border: 0;
}
.custom_table tr:not(.edit_dropdown_div) td.t_l_work,
.custom_table tr:not(.edit_dropdown_div) th.t_l_work {
    padding: 0;
}
.t_l_work label span {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
}
.t_l_work label {
    background: #fafafa;
    height: 100%;
    margin: 0;
    display: block;
    width: 100%;
    cursor: pointer;
    border-left: 1px solid #f7f7f7;
}
.custom_table tr:not(.edit_dropdown_div) td.t_l_work {
    padding: 0;
}
.t_l_work label input {
    display: none;
}
.t_l_work label span:after {
    display: block;
    content: "";
    height: 18px;
    width: 18px;
    background: #e1e1dd;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -9px 0 0 -9px;
}
.t_l_work label input:checked + span:after {
    background:#ec6b1c; 
}
.custom_table tr:not(.edit_dropdown_div) td.t_l_action i {
    color: #bfc5d2;
    font-size: 22px;
}
.more_results.text-center {
    height: 1px;
    background: #E4E8F0;
    margin: 40px 0;
}
.more_results.text-center a {
    display: inline-block;
    font-size: 14px;
    position: relative;
    transform: translateY(-51%);
    background: #F6F7FD;
    padding: 0 20px;
    color: #B0BAC9;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
}

.add_new_task {
    cursor: pointer;
    height: 40px;
    width: 40px;
    background: white;
    border-radius: 50%;
    display: block;
    font-size: 0;
    margin: -2px 0 0;
    box-shadow: 0 3px 12px rgba(255,23,68,0.4);
    position: relative;
}
.add_new_task:after,.add_new_task:before {
    display: block;
    content: "";
    height: 2px;
    width: 12px;
    background: #ec6b1c;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -1px auto 0;
}
.add_new_task:before {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
}


a.add_new_task {
    cursor: pointer;
    height: 40px;
    width: 40px;
    background: #fff;
    border-radius: 50%;
    display: block;
    font-size: 0;
    margin: -2px 0 0;
    box-shadow: 0 3px 12px rgba(255,23,68,0.4);
    position: relative;
}
a.add_new_task:after,a.add_new_task:before {
    display: block;
    content: "";
    height: 2px;
    width: 12px;
    background: #ec6b1c;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -1px auto 0;
}
a.add_new_task:before {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
}




tr.edit_dropdown_div {
    width: 100%;
    display: block;
    margin: -12px 0 21px;
}
tr.edit_dropdown_div.active {
    margin: -12px 0 11px;
}
tr.edit_dropdown_div > td {
    width: 100%;
    display: block;
    padding:0px;
    border:0px;
}
tr.edit_dropdown_div > td .edit_dropdown__inner{
    width: 100%;
    display: none;
    background: #fff;
    border: 1px solid rgba(46,91,255,0.15);
    padding: 30px;
}
.edit_d_head_right {
    justify-content: flex-end;
    display: flex;
    position: relative;
}
.select_wrap {
    display: block;
    position: relative;
    height: 36px;
}
.select_wrap select {
    height: 36px;
    display: block;
    background: #f9faff;
    border: 1px solid #e0e7ff;
    padding: 0 25px 0 12px;
    border-radius: 4px;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    width: 100%;
    font-size: 13px;
    cursor: pointer;
}
.select_wrap:after {
    width: 24px;
    height: 100%;
    position: absolute;
    top: 0px;
    cursor: pointer;
    content: "";
    background-color: #e0e7ff;
    /* background-image: url(../images/double-arror.png); */
    background-repeat: no-repeat;
    background-size: 9px auto;
    background-position: center center;
    border-radius: 0 4px 4px 0;
    right: 0;
    pointer-events: none
}
.edit_d_head_right .select_wrap {
    margin-right: 15px;
    min-width: 232px;
}
.edit_d_head_right .select_wrap select {
    width: 100%;
    -webkit-appearance: none;
}
.edit_dropdown_div table.table td,.edit_dropdown_div table.table th {
    font-size: 13px;
    padding: 15px 15px;
    border-color: #d9d9d9;
    line-height: 1.3;
}

.edit_dropdown_div table.table th {
    text-transform: uppercase;
    color: #b0bac9;
    padding: 10px 15px;
    font-weight: 500;
}
.edit_dropdown_div table.table tr td:first-child,
.edit_dropdown_div table.table tr th:first-child{
    padding-left: 0px;
}
.edit_d_head .row {
    align-items: center;
}
.edit_d_head{
    margin:0 0 15px;
}
.edit_d_head h6 {
    margin: 0;
    color: #8798ad;
    text-transform: uppercase;
    font-size: 13px;
}
.custom_table > tbody > tr:not(.edit_dropdown_div).opened {
    box-shadow: 0 2px 4px rgba(0,0,0,0.08);
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.08);
    -moz-box-shadow: 0 2px 4px rgba(0,0,0,0.08);
    position: relative;
}
.edit_d_head_right .btn {
    box-shadow: 0 0 13px rgba(0,82,255,0.2);
    -webkit-box-shadow: 0 0 13px rgba(0,82,255,0.2);
    -moz-box-shadow: 0 0 13px rgba(0,82,255,0.2);
    font-weight: 600;
    font-size: 13px;
    text-transform: capitalize;
}
a.edit_toggle{
    position: relative;
    padding-right: 22px;
}
a.edit_toggle:after {
    /* background-image: url(../images/down_arrow.png); */
    background-size: 10px auto;
    background-position: right center;
    background-repeat: no-repeat;
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 100%;
    transition: all ease 0.4s;
}
a.edit_toggle.active:after {
    transform: rotate(-180deg);
}




/*vimal*/    
ul.staff li{ display: flex; align-items: center;    line-height: 33px; margin-bottom: 15px; font-size: 14px;}
ul.staff li a{text-decoration: none;}

ul.staff li span.img{width: 35px; height: 35px; border-radius: 100%; display: inline-block; text-align: center; margin-right: 12px}
/* ul.staff li:nth-child(2) span.img{border:1px solid #ff0000; background: #ffcccc; color:#ff5252  }
ul.staff li:nth-child(3) span.img{border:1px solid #f5a623; background: #feeed4; color:#f8c36a}
ul.staff li:nth-child(4) span.img{border:1px solid #4a90e2; background: #dae8f9; color:#84b3ea }
ul.staff li:nth-child(5) span.img{border:1px solid #7ed321; background: #e5f6d3; color:#a7e069}
ul.staff li:nth-child(6) span.img{border:1px solid #50e3c2; background: #ddf9f3; color:#94edd9}
ul.staff li:nth-child(7) span.img{border:1px solid #bd10e0; background: #f2cff9; color:#d15ce9} */

ul.staff li span.txt-1{color:#6a6a6a}

#sidebar ul.nav{ border-bottom: 1px solid #f4f5f7; margin-bottom: 35px!important;}
#sidebar h2{font-size: 0.8rem!important; font-weight: 600;  text-transform:uppercase; color: #b0bac9;  margin-left: 15px;margin-bottom: 35px; }


.switch {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    padding-left: 46px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cfd6df;
  -webkit-transition: .4s;
  transition: .4s;
  transform: translateY(-50%);
}

.switch .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch input:checked + .slider {
  background-color: #2196F3;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
    width: 36px;
    height: 22px;
}
.slider.round:before {
  border-radius: 50%;
}
/*vimal*/




.full_time_switch {
    padding: 15px;
    font-size: 12px;
    color: #6a6a6a;
}
.full_time_switch strong {
    margin: 4px 0;
    display: inline-block;
}

/*30-04-2019*/
.custom_table .staff {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    border: 1px solid #000;
    background-color: rgba(0, 0, 0, .5);
    margin-right: 5px;
    user-select: none;
}
.custom_table .staff.red {
    border: 1px solid #ff0000;
    background: #ffcccc;
    color: #ff5252;
}
.custom_table .staff.orange {
    border: 1px solid #f5a623;
    background: #feeed4;
    color: #f8c36a;
}
.custom_table .staff.purple {
    border: 1px solid #bd10e0;
    background: #f2cff9;
    color: #d15ce9;
}
.custom_table .staff.blue {
    border: 1px solid #4a90e2;
    background: #dae8f9;
    color: #84b3ea;
}
.custom_table .staff.green {
    border: 1px solid #7ed321;
    background: #e5f6d3;
    color: #a7e069;
}
.custom_table .staff.cgreen {
    border: 1px solid #50e3c2;
    background: #ddf9f3;
    color: #94edd9;
}
.more-result {
    max-width: 90%;
    margin: 10px auto;
}
.more-result a,
.more-result a:hover {
    color: #999;
    background-color: #eee;
    font-size: 11px;
    display: block;
    padding: 5px 10px;
    width: 145px;
    margin: 0 auto;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
}
.more-result a:before {
    width: 175px;
    position: absolute;
    content: "";
    height: 1px;
    background-color: #eee;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

.over_due {
    text-transform: uppercase;
    color: #ff1744;
}
.custom_table tbody tr.table_break_heading {
    display: block;
    width: 100%;
}
.custom_table tbody tr.table_break_heading > td {    
    background-color: transparent;
    min-height: 1px;
    padding: 0;
    text-transform: uppercase;
    padding-top: 10px;
}

/** 10 May 2019 **/
.custom-select{
    -webkit-appearance: none;
    background-color: rgba(224, 231, 255, 0.2);
    /* background-image: url(../images/custom_arrows.png); */
    background-size: 24px 100%;
    background-position: right center;
    border-color: #E0E7FF;
    cursor: pointer;
}
#contacts_accordion .form-group .custom-select{background-size: 12px 100%; background-position: 212px center}
.custom-input {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-color: rgba(224, 231, 255, 0.2);
    border: 1px solid #E0E7FF;
    border-radius: 0.25rem;
}
.custom-select:focus,
.custom-input:focus {
    border-color: #ff5a16 ;
    outline: 0;
    /*box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);*/
    box-shadow: none;
}
textarea.custom-input{
    min-height: 120px;
}
.custom-control-label::before{
    background-color: #cfd6df;
    border-color: #cfd6df;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #33ac2e;
    background-color: #33ac2e;
}
.custom-control-input:focus ~ .custom-control-label::before{
    box-shadow: none;    
}
.custom-switch .custom-control-label::after{
    background-color: #ffffff;
}
.client_info-block .tab-pane .card-header{
    background: #fff;
    border-bottom: none;
}
.tab-pane .card-header a.card-link{
    padding-top:10px;
    padding-bottom:10px;
    color: #8798AD;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-transform: uppercase;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.tab-pane .card-header a.card-link > span >i{
    color: #2E384D;
    font-style: normal;
    text-transform: capitalize;
    font-size: 13px;
    padding-top: 15px;
    display: inline-block;
}
.tab-pane .card-header a.card-link .fa{
    color: #5b5e66;
    margin-left: auto;
    font-size: 24px;
    position: relative;
}
.tab-pane .card-header .card-link .fa-angle-down:before {
    content: "\f106";
}
.tab-pane .card-header .collapsed .fa-angle-down:before {
    content: "\f107";
}
.tab-pane .card-header .card-link .fa-angle-down:after  {
    content: "";
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 13px;
    position: absolute;
    right: 24px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-transform: capitalize;
    color: #2E384D;
}
.tab-pane .card-header .collapsed .fa-angle-down:after {
    content: "Show";
}
.client_info-block .tab-pane .form-group {
    margin-bottom: 1.2rem;
    position: relative;
}
.tab-pane .custom-control-label span {
    font-size: 82%;
    color: #8798AD;
}
.tab-pane .sub_label{
    color: #B0BAC9;
}
.tab-pane .show_pass {
    position: absolute;
    right: 1px;
    font-size: 12px;
    color: #2E5BFF;
    font-weight: bold;
    bottom: -16px;
    cursor: pointer;
}
.tab-pane ul.list-unstyled li .w-50{
    display: inline-block;
}
.tab-pane .text-secondary {
  color: #8798AD !important;
  font-size: 1rem;
}
.client_info-block .tab-pane .table{
    border-top: 2px solid #BFC5D2;
}
.client_info-block .tab-pane .table tr th{
    color:#BFC5D2;
    font-size: 14px; 
}
.client_info-block .tab-pane .table th, 
.client_info-block .tab-pane .table td{
    border-color: #BFC5D2;
}
.tab-pane .table td a.view{
    font-weight: 600;
    text-decoration: none;
}
.print-block{
    margin-left: auto;
    margin-right: auto;
    padding:20px;
    max-width: 940px; 
}
.print-block img{
    width: 236px;
    margin-bottom: 32px;
}
.print-block p {
    margin-bottom: 1.6rem;
    font-size: 12px;
    color: #303036;
    /* font-family: 'Rubik', sans-serif; */
}
.history-table .table{
    border-top: none !important; 
}
.history-table .table tr th{
    min-width: 132px;
}
.history-table .table tr td{
    min-width: 132px;
    background-color: #fff;
}
.history-table .table tbody tr{
    border-bottom: 12px solid #f6f7fd;
}
.history-table .table tr td .custom-select{
    min-width: 162px;
}
.tools-blk a {
    padding: 1rem;
    border: 1px solid #E4EAFF;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 3px;
    text-decoration: none;
    color: #2E384D;
}
.tools-blk a .rounded-circle {
    height: 48px;
    width: 48px;
    background: #F1F4F6;
    margin-right: 12px;
}
.tools-blk hr{
    margin-bottom: 1.5rem ;
}
.top-24{
    top:25px
}

.login-footer{
    align-items: center;
    width: 85%;
    display: flex;
    justify-content: space-between;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0 10px 10px;
}
.login-footer a{
    font-family: 'Source Sans Pro', sans-serif;
    color: #0c2638;
    font-size: 14px;
    line-height: 26px;
}
.login-footer p, .login-footer .privacy-policy{
    font-size: 11px;
    text-decoration: none;
}
.login-footer .privacy-policy a:first-child, .privacy_policy a:first-child{
    border-right: 1px solid;
    padding: 0 15px;
}
.login-footer .privacy-policy a:last-child, .privacy_policy a:last-child{
    padding: 0 0 0 15px;
}
/*My Responsive style
==================================================================*/
@media only screen and (max-width: 1199px) {
    .content-wrapper {
        padding: 30px 20px 30px 20px;
    }
    .local_times {
        margin: -30px -20px 30px -20px;
    }
    .local_times ul li {
        padding: 3px 10px;
    }
    .tabs_nav .nav-tabs .nav-item .nav-link, ul.c_right_nav_list li a {
        padding: 0 20px;
    }
    .login-footer{width: 100%;}
}
    @media only screen and (max-width: 1024px) {
        .login_right_banner {
            height: 100vh;
        }
        .tabs_nav .nav-tabs .nav-item .nav-link, ul.c_right_nav_list li a {
            padding: 0 13px;
        }

    }

    @media only screen and (min-width: 991px) {
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.mobile_navbar_toggle {
            margin-left: 0!important;
        }
    }

    @media only screen and (max-width: 991px) {
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.mobile_navbar_toggle {
            margin-left: 8px!important;
        }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown{
            position: relative;
        }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
            left: auto;
            right: 0;
            top: 60px;
            width: 300px;
        }
        .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand {
            display: block;
            margin: 0;
        }
        .sidebar-offcanvas {
            max-height: calc(100vh - 75px);
            top: 75px;
        }
        .navbar .navbar-brand-wrapper {
            width: 200px;
        }
        .navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
            font-size: 16px;
        }
        .navbar .navbar-menu-wrapper {
            width: calc(100% - 200px);
        }
        .login-footer{flex-wrap: wrap;justify-content: center;}
    }


    @media only screen and (max-width: 767px) {
        .nav-tabs.tabs_style_btn .nav-item .nav-link{
            min-width: 110px;
        }
        .header_right_btns {
            /* align-items: flex-start!important; */
            justify-content: flex-start!important;
        }
        h2, .h2 {
            font-size: 1.675rem;
        }
        .tabs_nav .nav-tabs .nav-item .nav-link, ul.c_right_nav_list li a {
            padding: 0 15px;
            height: 50px;
        }
        .content-wrapper {
            padding: 30px 15px 30px 15px;
        }
        .local_times {
            margin: -30px -15px 30px -15px;
        }
        .account .container-scroller{width: 100%;}
        .login_wrapper_inner {
            display: block;
            padding: 0 15px;
        }
        .login_wrapper_inner > .login_right {
            width: 100%;
        }
        .login_wrapper_inner > .login_left {
            display: none;
            width: 100%;
        }
        .nav-tabs.tabs_style_btn .nav-item {
            margin: 0 5px 8px;
        }
        .client_info-block .tab-pane .form-group{
            position: static;
        }
    }

    @media only screen and (max-width: 639px) {
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
            left: 15px;
            right: 15px;
            top: 60px;
            width: auto;
        }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown{
            position: static;
        }
        .tabs_nav {
            display: block;
            border: 0;
            background: transparent!important;
        }
        .tabs_nav .nav-tabs {
            padding: 0;
            margin:0 0 15px;
        }
        .tabs_nav > ul,.tabs_nav .nav-tabs {
            background: #fff;
            border: 1px solid rgba(46,91,255,0.08);
        }
        ul.c_right_nav_list li a {
            border-bottom-width: 0;
        }
        .tabs_nav .nav-tabs:not(.tab-right) .nav-item,
        ul.c_right_nav_list li {
            width: 50%;
        }
        .tabs_nav .nav-tabs .nav-item .nav-link, ul.c_right_nav_list li a {
            padding: 0 15px;
            height: 50px;
            text-align: center;
            justify-content: center;
            border-bottom-color: rgba(46,91,255,0.08);
        }
    }

    @media only screen and (max-width: 479px) {
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item {
            margin-left: 15px!important;
            margin-right: 0!important;
        }
        .navbar .navbar-brand-wrapper {
            width: 100%;
            text-align: center;
            height: auto;
        }
        .navbar .navbar-menu-wrapper {
            width: 100%;
            height: auto;
            display: block!important
        }
        .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
            width: 100%;
        }
        .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper {
            display: block!important;
        }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item {
            margin: 0!important;
            width: 20%;
            justify-content: center;
        }
        .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand {
            margin: 0 0 2px;
        }
        nav.navbar.fixed-top {
            padding: 5px 0!important;
            background: #fff;
        }
        .page-body-wrapper {
            padding-top: 100px;
        }
        .sidebar-offcanvas {
            max-height: calc(100vh - 100px);
            top: 100px;
        }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
            top: 100px;
        }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.mobile_navbar_toggle {
            margin-left: 0px!important;
        }
        /* .login_head h1{text-align: center;} */
    }

