.wrapper {
  display: flex;
  flex-direction: column;
  width: 240px;
  min-height: 200px;
  height: 100%;
  background-color: #e9ebf1;
  min-width: 200px;
  & > div:nth-child(1) {
    width: 100%;
    height: 3.5px;
    display: flex;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .columnName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 12px;

    span {
      font-size: 18px;
      font-weight: 500;
      color: #707090;
    }
    span:nth-child(2) {
      font-size: 14px;
      color: #ec6b1c;
      cursor: pointer;
    }
  }
  .tasks {
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 12px 9px;
  }
  .columnFooterSection {
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 8px 0 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #32325d;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 0.7;
    }
  }
  .cursorAuto{
    cursor: auto;
  }
}
.automation {
  margin-top: 8px;
  display: flex;
  gap: 12px;
  padding: 6px 6px;
  filter: drop-shadow(0px 0px 10px #d2d7e0);
  align-items: center;
  box-shadow: 0px 0px 10px #d2d7e0;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
}
