.wrapper {
  display: flex;
  flex-direction: column;

  .elipsis {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    width: 20px;
    position: relative;

    gap: 2px;

    & > span {
      width: 8px;
      height: 7px;
      border-radius: 50%;
      background-color: #d9d9d9;

      display: flex;
    }
  }
  .options {
    z-index: 1000;
    position: absolute;
    background-color: white;
    width: 128px;
    justify-content: center;
    align-items: center;
    box-shadow:
      0px 4px 6px -2px rgba(0, 0, 0, 0.05),
      0px 10px 15px -3px rgba(0, 0, 0, 0.1);

    transform: translate(-30%, 110%);
    bottom: 0px;
    display: flex;
    flex-direction: column;

    .option {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      padding: 15px 14px;

      &:hover {
        background-color: #d9d9d9;
      }
    }
  }
}
