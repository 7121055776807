.unreadEmailIndicator {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #dbeafe;
  color: #1e40af;

  font-size: 14px;
}