.wrapper {
  position: relative;
  width: 100%;
  height: fit-content;
}

.controlWrapper {
  display: flex;
  color: #7d8c9d;
  cursor: pointer;

  border: 1px solid #d1d5dc;
  border-radius: 8px;
}

.controlContent {
  line-height: 16px;
  width: 100%;
}

.controlButton {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  height: 16px;

  margin-left: 8px;
  border: 0;
  padding: 0;
  background-color: transparent;

  svg {
    width: 16px;
    height: inherit;
    color: #6c7180;
    transform: rotate(-90deg);
  }
}

.dropdownWrapper {
  z-index: 1;
  position: absolute;
  top: 100%;
  margin: 4px 0;
  width: 100%;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 8px;
}

.dropdownSearchWrapper {
  width: 100%;
  padding: 4px;
}

.headOptionWrapper {
  display: flex;
  padding: 8px;
  background-color: #e8eaec;
}

.headOptionLabel {
  width: 100%;
  line-height: 20px;
  color: #4b5462;
  font-weight: 600;
}
.fromSearch {
  border: none;
  background: none;
  margin: 0;
}
.headOptionButton {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 20px;
  height: 20px;
  padding: 0;

  background-color: #7d8b9e;

  border: 0;
  border-radius: 50%;

  svg {
    min-width: 12px;
    height: 12px;

    path {
      fill: white;
    }
  }
}

.tailOptionWrapper {
  cursor: pointer;
  line-height: 20px;
  padding: 10px 8px;
}
