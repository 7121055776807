.overdueTaskRow {
    height: 75px;
    background-color: white;
}

.dueDateCell {
    border-left: 10px solid #ef4444 !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    color: #ef4444 !important;
    width: 10%;
}

.clientCell {
    width: 15%;
}

.titleCell {
    width: 15%;
}

.wipCell {
    width: 5%;
}

.descriptionCell {
    width: 15%;
}

.createdOnCell {
    width: 10%;
}

.optionsCell {
    width: 15%;
}

.doneCell {
    width: 10%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.ellipsis {
    position: relative;

    &::before {
        content: '&nbsp;';
        visibility: hidden;
    }

    span {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.center {
    position: relative;

    span {
        text-wrap: nowrap;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.optionButton {
    height: 25px;
    width: 40px;
}

.done {
    color: #16a34a;
}

.inProgress {
    color: #0284c7;
}