.tableRow {
  border-bottom: 1px solid transparent;
}

.typeCell {
  font-weight: 600;
}

.loggedCell {
  color: #6B7280;
}

.debtorsCell {
  color: #F3742B;
  font-weight: 700;
}

.totalPaymentCell {
  font-weight: 700;

  span {
    right: 20px;
    left: 20px;
  }
}
.totalSumCell {
  span {
    right: 25px;
    left: 70px;
  }
}