.invoice {
  padding: 35px;
  @include directify($directions) {
    text-align: directed('left');
  }

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.invoice__head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  flex-wrap: wrap;

  p {
    color: $color-additional;
    margin: 0;
  }
}

.invoice__logo {
  width: 120px;
  height: 16px;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;
  background-image: url('../../shared/img_replace/logo/logo.png');
  // @include themify($themes) {
  //   background-image: themed('logoImg');
  // }
}

.invoice__head-right {
  width: 100%;
  margin-top: 30px;

  .invoice__date {
    margin-bottom: 10px;
  }
}

.invoice__total {
  @include directify($directions) {
    text-align: directed('right');
  }
  margin-top: 15px;
}

.invoice__grand-total {
  font-weight: 500;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.invoice__toolbar {
  justify-content: flex-end;

  button {
    @include directify($directions) {
      #{directed('margin-left')}: 15px;
    }
    @include directify($directions) {
      #{directed('margin-right')}: 0px;
    }
  }
}

@media screen and (min-width: 768px) {

  .invoice {
    padding: 50px;
  }

  .invoice__head-right {
    width: auto;
    @include directify($directions) {
      text-align: directed('right');
    }
    margin-top: 0;
  }
}

// Pratik Invoice
.invoice_main{
  textarea{
    background: none;
    background-color: transparent;
    border: none;
    font-size: 16px;
    overflow: hidden;
    &:hover,&:focus{
      outline: none;
      border: 1px solid #ff5a16;
    }
  }
  input[type="text"]{
    background: none;
    background-color: transparent;
    border: none;
    font-family: arial,sans-serif;
    font-size: 14px;
    &:hover,&:focus{
      outline: none;
      border: 1px solid #ff5a16;
    }
  }
  td{
  }
  tr{
    th{
      background: #dbdad9;
    }
    &.seperated  {
      td{
        border-top-style: none;
        border-bottom-style: none;
        border-left: 1px solid #dbdad9;
        border-right: 1px solid #dbdad9;
        text-align: center;
        input{
          padding:3px 10px;
        }
      }
    }
    &.outlined{
      td,th{
        border-width: 1px;
        border-color: #dbdad9;
        border-style: solid;
      }
    }
  }
  li{
    font-size: 14px;
  }
}
.outlined{
  // border-width: 1px;
  // border-color: #dbdad9;
  // border-style: solid;
  input[type="text"]{
    background: none;
    background-color: transparent;
    border: none;
    font-family: arial,sans-serif;
    font-size: 14px;
  }
}

// Print css
@media print {
  button {
    display: none !important;
  }
  input,
  textarea {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

@page {
  size: A4;
  margin: 0;
  margin-top: 60px;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  /* ... the rest of the rules ... */
}
