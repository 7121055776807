.modalWrapper {
  height: 75%;
  width: 90%;
  z-index: 10000;
  border-radius: 32px;
  padding: 0;
}

.modalLayout {
  background-color: black;
  z-index: 1;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 32px;
  background-color: #edf2f7;
  height: 108px;
}

.modalTitle {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  padding: 16px 24px;
  color: #2d3748;
}

.searchInput {
  max-width: 298px;
  width: 100%;
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 32px;
  background-color: #edf2f7;
  width: 100%;
  height: 96px;
}

.tableWrapper {
  padding: 32px 32px 0;
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.headerCell {
  font-weight: 600;
}

.radioCell {
  padding: 0;
}

.selectButton {
  max-width: 335px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px !important;
  line-height: 28px !important;
}

.tableBorder {
  display: flex;
  border: solid #e2e7f1 1px;
  border-radius: 8px 8px 0 0;
  min-height: 100%;
  width: 100%;
  table {
    border: none;
    margin-bottom: auto;
  }
}
