.table {
  @include directify($directions) {
    text-align: directed("left");
  }

  &:last-child {
    margin-bottom: 0;
  }

  tbody td,
  th {
    border: none;
    padding: 10px;

    @include themify($themes) {
      color: themed("colorTextAdditional");
    }
  }

  thead th {
    @include themify($themes) {
      color: themed("colorText");
      border-bottom-color: themed("colorBorder");
    }
  }

  .badge {
    color: #ffffff;
    padding: 6px 20px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 400;

    &.badge-success {
      background-color: $color-accent;
    }

    &.badge-warning {
      background-color: $color-yellow;
    }

    &.badge-primary {
      background-color: $color-blue;
    }

    &.badge-danger {
      background-color: $color-red;
    }

    &.badge-disabled {
      background-color: #dddddd;
    }
  }

  &.table--bordered {
    @include directify($directions) {
      text-align: directed("left");
    }
    tr {
      @include themify($themes) {
        border-bottom: 1px solid themed("colorBorder");
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &.table--head-accent {
    thead {
      @include themify($themes) {
        color: themed("colorTextAdditional");
        background-color: themed("colorHover");
      }

      th {
        border-bottom: none;
      }
    }
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      @include themify($themes) {
        background-color: themed("colorHover");
      }
    }
  }

  &.table-hover {
    tbody tr {
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        @include themify($themes) {
          background-color: themed("colorBackgroundBody");
        }
      }
    }
  }
}

// Add Task Table
.addTaskTitle {
  color: $subtitle-text;
  font-size: 18px;
}
.task_status {
  width: 110px;
  display: inline-block;
}
.task_action {
  margin: 0 25px;
}
.table_wrap {
  overflow-x: auto;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  margin: 0 -15px 10px;
}
.min_height_50 {
  // min-height: 400px;
  // overflow: auto;
}
.addtaskBody {
  padding: 30px;
  @include mediaq(1200px) {
    padding: 15px;
  }
}

// Dashboard
.boxshadow_tbl {
  // border-bottom: 15px solid transparent;
  // box-shadow: 2px 1px 3px rgba(0,0,0,0.1);
  box-shadow: 0 2px 10px 0 hsl(0, 0%, 87%), 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
  overflow: hidden !important;
}
.inner_tbl {
  margin: 8px -15px 7px;
  // box-shadow: inset 0px 2px 6px 0px #dedede;
  box-shadow: 0 2px 10px 0 #dedede, 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px !important;
}
.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root {
  border: none !important;
}
.checkbox_wrap {
  background: $checkBg;
}
.staff_name {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
}
.dropdown_height {
  max-height: 300px;
  .MuiPopover-paper {
    padding-top: 15px;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: $fieldTitle;
    }
    &::-webkit-scrollbar-track {
      background: $color-light-gray;
    }
  }
}
.table_head_dropdown {
  list-style-image: none;
  list-style: none;
  // position: absolute;
  // top: 100%;
  // left: 0;
  // padding-left: 0;
  // margin: 0;
  // background: $white;
  // width: 230px;
  padding: 0px 20px;
  // box-shadow:0 0px 10px 0 #ddd, 0 3px 7px 0 rgba(0, 0, 0, 0.1);
  // // display: none;
  // z-index: 2;

  // overflow: auto;

  &.active {
    display: block;
  }
  // li{
  margin: 7px 0;
  .radio-btn__label {
    font-weight: 400;
  }
  // }
}
.round_radio,
.round_checkbox {
  position: relative;
}
.round_radio label {
  background-color: $white;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 28px;
  transform: translate(-50%, -50%);
}
.round_checkbox label {
  background-color: $white;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 18px;
  transform: translate(-50%, -50%);
}

.round_radio label:after {
  border: 2px solid $white;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}
.round_checkbox label:after {
  border: 2px solid $white;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 10px;
}

.round_radio input[type="checkbox"],
.round_checkbox input[type="checkbox"] {
  visibility: hidden;
}

.round_radio input[type="checkbox"]:checked + label,
.round_checkbox input[type="checkbox"]:checked + label {
  background-color: $color-orange;
  border-color: $color-orange;
}

.round_radio input[type="checkbox"]:checked + label:after,
.round_checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

// task page
.overdue {
  // border-left: 10px solid $title-overdue;
  position: relative;
  &::before {
    border-left: 8px solid $title-overdue;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: 5px 0 0 5px;
  }
}
.overdue_title {
  color: $title-overdue !important;
}
.overdue_date {
  color: $title-overdue !important;
  font-weight: 400 !important;
  margin-left: 5px;
}

table th {
  padding: 16px;
  color: $tableHead;
  font-size: 16px !important;
  position: relative;
}
.table_head_dropdown {
  .custom-control-label {
    font-weight: normal;
  }
}
.round_three {
  max-width: 120px;
  @include mediaq(1600px) {
    max-width: 120px;
    width: 150px;
  }
}

// table inside input
@include mediaq(768px) {
  .inp_width_md {
    width: 150px !important;
  }
  .inp_width_sm {
    width: 100px !important;
  }
}
.transactions_close.modal-header {
  position: absolute;
  right: 0;
  top: -3px;
  border: none;
  padding: 5px;
  text-align: center;
}

.transactions_table {
  th {
    padding: 16px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
  }
  .search_box {
    align-items: center;
    label {
      line-height: 32px;
      margin-bottom: 0;
    }
    input {
      max-width: 250px;
      border: 1px solid #949494;
      background-color: #f3f5f7;
      margin: 0 10px;
    }
    .btn-gray {
      background-color: #fff;
      color: #000;
    }
  }
  td {
    button {
      &#Search {
        margin: 0 !important;
      }
      label {
        margin-bottom: 0;
      }
      svg {
        width: 20px;
        height: 21px;
        margin-top: 0;
        vertical-align: middle;
        margin-left: 10px;
      }
    }
  }
  tr.green_table
  {
    background-color:  #50e3c233 !important;
  }
  .green_table {
    .search_table_header {
      background-color: #f2fdfa !important;

      th {
        color: #417505 !important;
      }
    }
    .search_table_warpper {
      background-color: #e5fbf6 !important;
      border: 1px solid #50e3c2 !important;
    }
  }
  input._input
  {
    border: none;
    background-color: transparent;
  }
  input._input_error
  {
    border: 1px solid red;
    background-color: transparent;
    &:focus
    {
      outline: none;
      border: 1px solid #333;
      &::placeholder
      {
        color: transparent;
      }
    }
    &::placeholder
    {
      color: red;
    }
  }
  
}

.input_data
{
  width: 120px;
  display: inline-block;
}
.search_table {
  background-color: #f5f9fc;
  .search_table_header {
    background-color: #ffff;

    th {
      padding: 16px;
      border-bottom: 0;
    }

    td {
      background: #f9fbfd;
    }
  }


  .search_table_main {
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    background: #fff;
    padding: 16px 0;
    .search_table_warpper {
      padding: 16px;
      background-color: #f5f9fc;
      border: 1px solid #dde1e4;
      .btn-gray {
        background: #fff;
        &:hover {
          background: #f2f5f8;
        }
        &.payment-button
        {
          width: 100%;
        }
      }
    }
  }
}
.Transactions_warpper
{
  display: contents;
}