.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.hiddenFileInput {
  display: none;
}

.fileInputWrapper {
  display: flex;
  height: 42px;
  line-height: 21px;
  font-size: 14px;
}

.selectFileButton {
  border-radius: 8px 0 0 8px;
  text-wrap: nowrap;

  background-color: #4B5563;
  border: 0;

  color: white;
  font-weight: 500;
  padding: 10.5px;
}

.selectFileLabelWrapper {
  width: 100%;
  padding: 9.5px;
  border: 1px solid #D1D5DB;
  border-left: 0;
  border-radius: 0 8px 8px 0;
}

.selectFileLabel {
  line-height: 21px;
}

.selectedFileList {
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  height: 46px;
  width: 100%;
  padding: 11.5px;
  line-height: 21px;
  border: 1px dashed #D1D5DB;
  background-color: #F3F4F6;
  border-radius: 8px;
}

.selectedItemWrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  background-color: white;
  border-radius: 4px;
  padding: 2px 4px;
}

.selectedItemName{
  text-wrap: nowrap;
  line-height: 18px;
}

.removeSelectedItemButton {
  border: 0;
  background-color: transparent;
  padding: 0;

  svg {
    width: 18px;
    min-width: 18px;
    height: 18px;
  }
}

.labelWrapper {
  color: black;
  margin-bottom: 8px;
  text-wrap: nowrap;
}

.label {
  margin: 0;
  margin-right: 8px;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}

.error {
  margin: 0;
  color: #ef4444;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}