.dateRangeWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 100%;
}

.dateRangeList {
  display: flex;
  gap: 5px;
}

.dateRangeItem {
  display: flex;
  width: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: default;

  &.active {
    color: #fdba74 !important;
  }

  &.clickable {
    cursor: pointer;
  }
}

.dateRangeAction {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}


.hourFieldsWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hourField {
  border: 0;
  background-color: transparent;
  outline: 0;

  div {
    padding: 0;
  }

  input {
    width: 70px;
    padding: 0 !important;
  }

  fieldset {
    border: 0 !important;
  }
}