.selectWrapper {
  position: relative;
  width: 100%;

}

.controlWrapper {
  width: 100%;
  background-color: white;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  height: 38px;

  display: flex;
}

.inputWrapper {
  flex: 1 1 auto;
  padding-left: 8px;
}

.input {
  border: 0;
  outline: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  font-size: 16px;
}


.selectButton {
  border: 0;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
}

.arrowIcon {
  transform: rotate(-90deg);
  width: 24px;
  height: 24px;
}

.dropdownWrapper {
  position: absolute;
  top: 100%;
  z-index: 1;
  width: 100%;

  padding: 4px 0;
  margin: 8px 0;

  border: 1px solid #CCCCCC;
  border-radius: 4px;
  background-color: white;
}

.dropdownList {
  min-height: 35px;
  max-height: 300px;
  overflow-y: scroll;

}

.dropdownItem {
  padding: 8px 12px;
  font-size: 16px;

  &:hover {
    background-color: rgb(222, 235, 255);
  }

  &.selected {
    color: white;
    background-color: rgb(38, 132, 255)
  }
}

.center {
  text-align: center;
  font-size: 16px;
  padding: 8px 12px;
}