.taskNoteModal {
    width: 100%;
    height: 100%;
    position: relative;
}

.closeIcon {
    position: absolute;
    cursor: pointer;
    top: -30px;
    font-size: 30px;
    left: -15px;
}

.taskNoteWraper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
}

.replyButton {
    background-color: #1d4ed8;
    color: white;
    padding: 10px 10px;
    width: 95px;
  
    font-size: 16px;
    font-weight: 500;
  
    &:hover{
      background-color: #1d40b0;
      color: white;
    }
  
    svg {
      min-width: 20px ;
      height: 20px;
    }
  }