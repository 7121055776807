.wipCostFormWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.actionButton {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: black;


  svg {
    width: 32px;
    height: 32px;

    path {
      fill: black;
      stroke: black;
    }
  }
}

.actionButtonSmall {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: black;


  svg {
    width: 24px;
    height: 24px;

    path {
      fill: black;
      stroke: black;
    }
  }
}