$fontColor: #111827;

.wipInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.navbarWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;
}

.overdueWrapper {
  color: red;
  font-size: 18px;
  border: 1px solid red;
  font-weight: bold;
  border-radius: 8px;
  padding: 12px 20px;
}

.buttonsWrapper {
  display: flex;
  gap: 8px;
}