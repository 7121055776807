.taskInfoWrapper {
  display: flex;
  flex-direction: column;

  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  line-height: 27px;
  /* 168.75% */

  padding: 10px 20% 10px 14px;

  gap: 8px;
  border-radius: 5px;
  background: rgba(204, 204, 215, 0.31);


}

.text {
  line-height: 24px;
  margin: 0;
}

.overdueText {
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #FF0000;
  margin: 0;
}

.debtorsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;
}

.highlightedText {
  color: #ff6b00;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  margin: 0;

  &.underline {
    cursor: pointer;
    text-decoration: underline;
  }
}

.textWrapper {
  display: flex;
  gap: 8px;
}