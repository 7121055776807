$base-color: #ddd;

.emailItemSkeletonWrapper {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 10px;

  border-bottom: 1px solid #ebedf0;

  padding: 0 30px 0 15px;

  height: 56px;
}


.line {
  float: left;
  width: 100%;
  height: 26px;
  border-radius: 2px;
  background-color: $base-color;

  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  &.square {
    width: 26px;
    min-width: 26px;
    height: 26px;
  }

  &.senderPhoto {
    width: 26px;
    min-width: 26px;
    height: 26px;
    border-radius: 50%;
  }

  &.senderName {
    width: calc(15% - 26px);
  }

  &.creationTime {
    width: 20%;
  }

  &.subject {
    width: 100%;
  }

}


@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

