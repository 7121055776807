.claimDetailsNoteListWrapper {
  position: relative;
  overflow-y: scroll;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #cacaca;
  height: 150px;
  width: 400px;
  padding: 17px 17px 0 17px;


  &::-webkit-scrollbar {
      width: 17px;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      border-left: 1px solid #cacaca;
      border-bottom: 1px solid #cacaca;
      border-top: 1px solid #cacaca;
  }

  &::-webkit-scrollbar-track {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: 1px solid #cacaca;
      background-color: #ccccd7;
  }

  &::-webkit-scrollbar-thumb:hover {
      background-color: #ffffff;
  }
}

.noteOpenButton {
  position: absolute;
  color: #777671;
  cursor: pointer;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 24px;
  width: 67px;
  border-radius: 0.25em;
  padding: 4px;
  border: 1px solid #dedede;
  transition: all 0.3s ease 0s;

  &:hover {
      background-color: #efefee;
  }
}

.noteItem {
  position: relative;
  display: flex;
  padding: 5px 10px;
  align-items: center;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
  justify-content: space-between;
  gap: 10px;
  width: 100%
}

.avatarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: black;
  border-radius: 50%;
  border: 1px solid black;
  min-width: 30px;
}

.noteItemContent {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.noteHeader {
  font-weight: 600;
  font-size: 13px;
}

.noteTitle {
  margin: 0;
  font-size: 13px;
  line-height: 21px;
  height: 21px;
}

.noteDate {
  flex: 0 0 120px;
  display: flex;
  align-items: end;
  height: 35px;
  gap: 10px;

  p {
      font-weight: 600;
      font-size: 13px;
  }
}