$borderColor: #e5e7eb;
$fontColor: #1F2A37;

.dropdownWrapper {
  user-select: none;
  width: 140px;
  position: relative;
  color: $fontColor;
}

.dropdownHeader {
  position: relative;
  z-index: 3;
  border: 1px solid $borderColor;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  display: flex;
  gap: 4px;
  align-items: center;
  line-height: 18px;
}

.dropdownHeaderContent {
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: center;

  &.withCloseIcon {
    gap: 0;
    width: calc(100% - 22px);
  }
}

.dropDownHeaderLabel {
  text-wrap: nowrap;
  overflow: hidden;
}

.dropdownIcon {
  min-width: 18px;
  height: 18px;

  &.down {
    margin-left: auto;
    transform: rotate(-90deg);
  }
}

.dropdownListWrapper {
  min-width: 100%;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 30px;
  background-color: white;
  padding: 8px;
  border: 1px solid $borderColor;
  border-top: 0;
  border-radius: 0 0 8px 8px;
}

.dropdownList {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0;
  list-style: none;
  line-height: 22px;
  max-height: calc(27px * 10) ;
  overflow-y: scroll;
}

.dropdownItem {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &.selected {
    font-weight: 600;
  }
}

.closeIcon {
  min-width: 18px;
  height: 18px;
  cursor: pointer;
}