.sectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  max-width: 380px;

  &.full {
    max-width: none;
  }
}

.debtorPayTrackingList {
  list-style: none;
  padding: 0;
}

.debtorPayTrackingItem {
  display: flex;
  gap: 8px;
  align-items: center;
}

.subSection {
  width: 100%;
  cursor: pointer;

  &.selected {
    background-color: #f3f4f6;
  }
}