.form-div {
  margin: 15px;
  position: relative;
  max-width: 100%;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 #ddd;
  padding: 20px;
  form {
    display: flex;
    flex-direction: column;
    gap: 14px;
    label {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: space-between;
      span{
        max-width: 100px;
        width: 100%;
      }
      input {
        max-width: 100%;
        width: 100%;
        padding: 5px 10px;
        font-size: 16px;
        line-height: 16.8px;
        border-radius: 5px;
        border: 1px solid silver;
        height: 32px;
        transition: border 0.3s;
        background: #f5f9fc;
        font-weight: 400;
        outline: none;
      }
      input:focus {
        border: 1px solid #fd7e14;
      }
      textarea {
        max-width: 100%;
        width: 100%;
        padding: 5px 10px;
        font-size: 16px;
        line-height: 16.8px;
        border-radius: 5px;
        border: 1px solid silver;
        height: 70px;
        transition: border 0.3s;
        background: #f5f9fc;
        font-weight: 400;
        outline: none;
      }
      textarea:focus {
        border: 1px solid #fd7e14;
      }
    }
    .file-container {
      max-width: 900px;
      width: 100%;
      height: 300px;
      display: flex;
      position: relative;
      cursor: pointer;
      border: 1px solid #c5dff2;
    }
  }
}
