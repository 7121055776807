.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: white;
  padding: 9px;
  cursor: pointer !important;
  gap: 15px;
  justify-content: space-between;
  & > span:nth-child(1) {
    font-size: 12px;
    font-weight: 500;
    color: #707090;
  }

  & > span:nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    color: #707090;
  }

  .text {
    display: flex;
    width: fit-content;
    padding: 2px 4px;
    color: white !important;
    border-radius: 2px;
  }
  .low {
    background: #42a5f5;
  }
  .medium {
    background: #fbc02d;
  }
  .high {
    background: #ff1744;
  }

  .footerSection {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 7px;
    }
    & > div:nth-child(2) {
      gap: 15px;

      & > span:nth-child(n + 1) {
        justify-content: flex-end;
      }
    }
  }
  .assigneeContainer {
    width: 30px;
    display: flex;
    position: relative;

    & > *:nth-child(n + 1) {
      margin-left: -15px;
    }
    & > *:nth-of-type(2) {
      z-index: 3; /* Highest z-index to ensure this one is on top */
    }

    & > *:nth-of-type(3) {
      z-index: 2; /* Second in the stack */
    }

    & > *:nth-of-type(3) {
      z-index: 1; /* Third in the stack */
    }
    & > *:nth-of-type(n + 4) {
      display: none; /* Hide all icons beyond the third */
    }
  }
}
