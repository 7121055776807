.tableRow {
  &.active {
    span {
      color: red;
    }
  }
}

.actionButton {
  padding: 10px !important;

  span {
    color: black !important;
  }
}