$borderColor: #D1D5DB;
$backgroundColor: #F9FAFB;

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 330px;
}

.textSectionWrapper {
  border-radius: 8px;
  background-color: $backgroundColor;
  border: 1px solid $borderColor;
}

.dropdownWrapper {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 16px;
}

.textSectionActionWrapper {
  border-top: 1px solid $borderColor;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textarea {
  border: 0;
  outline: none;
  width: 100%;
  padding: 12px;
  height: 140px;
  background-color: transparent;
  resize: none;
}

.labelWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.error {
  color: red;
  font-size: 14px;
  line-height: 24px;
}
