.modalWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.closeIcon { 
  position: absolute;
  cursor: pointer;
  font-size: 30px;
  top: -35px;
  left: -20px;
}