.propertiesListWithSearch {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.propertiesList {
  display: flex;
  flex-direction: column;
  max-height: 255px;

  padding: 0;
  list-style: none;

  overflow: scroll;
}

.propertyListItemWrapper {
  display: flex;
  gap: 16px;

  &.selected {
    background-color: #f5f5f5;
  }
}

.subSection {
  width: 100%;
}

.checkBoxWrapper {
  padding: 14px 0 0 0;
}