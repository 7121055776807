.slideModal {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100vh;
    overflow-y: scroll;
    background-color: white;
    animation: slide-up 0.3s ease-out forwards;
    padding: 40px 30px;
    position: fixed;
    top: 0;
    right: 0;

    z-index: 1001;
}

.layout {
    display: flex;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 150;
    top: 0;
    left: 0;
    opacity: 0.7;
}

.closing {
    animation: slide-down 0.3s ease-in-out;
}


@keyframes slide-down {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes slide-up {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}