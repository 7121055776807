.noteSection {
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 50%;
    height: 100%;
    background-color: #efeff3;
    gap: 10px;
    padding: 40px 40px;
}

.combinedNoteListWrapper {
    position: relative;
    overflow-y: scroll;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #cacaca;
    height: 100%;
    width: 100%;
    padding: 17px 17px 0 17px;


    &::-webkit-scrollbar {
        width: 17px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;

        border-left: 1px solid #cacaca;
        border-bottom: 1px solid #cacaca;
        border-top: 1px solid #cacaca;
    }

    &::-webkit-scrollbar-track {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: 1px solid #cacaca;
        background-color: #ccccd7;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #ffffff;
    }
}

.noteItem {
    position: relative;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
    justify-content: space-between;
    gap: 10px;
    width: 100%
}

.avatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    color: black;
    border-radius: 50%;
    border: 1px solid black;
    min-width: 30px;
}

.noteItemContent {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
}

.noteOpenButton {
    position: absolute;
    color: #777671;
    cursor: pointer;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: white;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 24px;
    width: 67px;
    border-radius: 0.25em;
    padding: 4px;
    border: 1px solid #dedede;
    transition: all 0.3s ease 0s;

    &:hover {
        background-color: #efefee;
    }
}

.noteHeader {
    font-weight: 600;
    font-size: 13px;
}

.notFound {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.noteTitle {
    margin: 0;
    font-size: 13px;
    line-height: 21px;
    height: 21px;
}

.email {
    color: #039D00 !important;

    h5 {
        color: #039D00 !important;
    }
}

.sms {
    color: #2E5BFF !important;

    h5 {
        color: #2E5BFF !important;
    }
}

.call {
    color: #FF6B00 !important;

    h5 {
        color: #FF6B00 !important;
    }

}

.important {
    color: #FF1744;

    h5 {
        color: #FF1744;
    }
}

.noteDate {
    flex: 0 0 120px;
    display: flex;
    align-items: end;
    height: 35px;
    gap: 10px;

    p {
        font-weight: 600;
        font-size: 13px;
    }
}

.noteInput {
    outline: none;
    border: 1px solid #cacaca;
    border-radius: 6px;
    height: 100px;
    padding: 20px;
    resize: none;
}


.cancelButton {
    color: black;
    font-weight: 600;
    border: 0;
    padding: 11px 20px;
    font-size: 15px;
    margin-right: 10px;
    border-radius: 6px;
    line-height: 15.8px;
    background-color: #e4e8f0;
}