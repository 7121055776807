.emailBodyWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  flex: 1 1 auto;

  padding: 16px;

  overflow-y: scroll;
}

.senderInformationWrapper {
}

.senderInformation {
  display: flex;
  align-items: center;
  gap: 15px;
}

.senderImage {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
}

.senderProfile {
  display: flex;
  flex-direction: column;
}

.senderName {
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
}

.senderEmail {
  line-height: 20px;
}

.subject {
  font-weight: 700;
  font-size: 24px;
  margin: 16px 0;
}
