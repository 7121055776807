.wrapper {
    display: flex;
    height: 40px;
    border: 1px solid #e6eaed;
    border-radius: 4px;
    overflow: hidden;
    color: #717274;
    user-select: none;
}

.item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;

    transition: all 0.3s ease 0s;

    &:hover {
        background-color: #ebeff2;
    }

    &:not(:first-child) {
        border-left: 1px solid #e6eaed;
    }
}

.item.active {
    background: #e5eef9;
    color: #1876d2;
}