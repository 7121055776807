.idCell {
  color: #6B7280;
}

.nameCell {
  font-weight: 700;
  color: #F3742B;
}

.phoneNumberCell {
  a {
    font-size: 16px;
  }
}