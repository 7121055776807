.taskBoard {
    display: flex;
    width: 100%;
    min-height: 100%;
    background-color: white;
    overflow-x: auto;
    padding: 27px 30px;
    gap: 30px;
    min-height: 520px;
    overflow-y: auto;

    .empty {
      display: flex;
      width: 100%;
      min-height: 420px;
      justify-content: center;
      align-items: center;
    }
  }