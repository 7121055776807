.emailTableFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.searchFieldWrapper {
  width: 400px;
}

.syncWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;

  width: 100%;
  padding: 0 16px 16px 16px;
}

.syncText {
  font-size: 13px;
  color: black;
}