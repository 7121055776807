.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 600px;
}

.buttonsWrapper {
  display: flex;
  gap: 8px;
  justify-content: end;
}