.star {
  color: transparent;

  width: 24px;
  height: 24px;

  cursor: pointer;

  path {
    stroke: #6b7280;
  }

  &.active {
    color: #faca16;

    path {
      stroke: #faca16;
    }
  }

  &:hover {
    path {
      stroke: #faca16;
    }
  }
}