$borderColor: #E5E7EB;

.addressHistoryList {
  list-style: none;
  padding: 0;
  width: 100%;
}

.addressHistoryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $borderColor;

  span {
    cursor: pointer;
    padding: 16px 0;
    display: block;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    text-wrap: nowrap;
    width: 100%;
  }

  &.selected {
    background-color: #F3F4F6;
  }
}
.addressHistoryItemTextWrapper {
  width: 100%;
}

.closeButton {
  padding: 0;
  border: 0;
  background-color: transparent;
  min-width: 21px;
  height: 21px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.addressHistoryWithSearchWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}