.st0 {
  fill: #F25022;
}

.st1 {
  fill: #7FBA00;
}

.st2 {
  fill: #00A4EF;
}

.st3 {
  fill: #FFB900;
}