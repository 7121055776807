.modalWrapper {
  width: 820px;
  z-index: 1000;
}

.modalContent {
  position: relative;
  width: 100%;
  height: 100%;
}

.closeButton {
  position: absolute;
  top: -35px;
  left: -15px;
  font-size: 30px;
  cursor: pointer;
}