.listWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contactInformation {
  flex: 1 1 auto;
}

.row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.actionButton {
  padding: 0;
  background-color: transparent;
  border: none;

  svg {
    width: 20px;
    height: 20px;
  }
}

.contactActions {
  display: flex;
  align-items: center;
  gap: 8px;

  padding-left: 16px;
}

.contactItemWrapper {
  display: flex;
}