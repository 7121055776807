.wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;


    .content {
        display: flex;
        flex-direction: column;

        gap: 15px;

        textarea {
            border-radius: 5px;
            border: 1px solid #cfcfcf;
            padding: 10px;
            background: rgba(152, 152, 174, 0.1);
            height: 125px;
            width: 100%;
            outline: none;
        }

        .headerText {
            color: #000;

            font-family: Source Sans Pro;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }
    }
}

.error{ 
    margin-top: 5px;
    font-size: 12px;
    color: red;
}