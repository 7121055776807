.subTaskModal{
    display: flex;
    flex-direction: column;
    gap: 15px;

    position: relative;
}

.cancelButton{
    border: 0;
    background-color: white;
    position: absolute;
    font-size: 20px;
    top: -25px;
    right: -35px;
}
