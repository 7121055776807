.wrapper {
  background: #7d8c9d;
  width: 30px;
  height: 17px;
  border-radius: 25px;
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    top: 2px;
    left: 2px;
    transition: 0.2s;
  }

  &.left {
    background-color: rgb(88, 178, 88);
    &::before {
      left: 14px;
    }
  }
}
