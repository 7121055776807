
.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.actionWrapper {
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: end;
}

.row {
  display: flex;
  gap: 16px;
}