.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.submitButtonWrapper {
  display: flex;
  justify-content: end;

  width: 100%;
}