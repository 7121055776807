.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.row {
  display: flex;
  gap: 16px;

  &.end {
    justify-content: end;
  }
}