$cell-width: 120px;
$cell-height: 100px;

$grid-width: $cell-width * 7;
$border-width: 1px;
$border-color: #ccc;

.wrapper {
  display: grid;
  width: 70vw;
  height: 60vh;
  border-bottom: $border-width solid $border-color;

  .calendarHeader {
    display: grid;
    grid-template-columns: minmax(20px, $cell-width) repeat(7, minmax(20px, $cell-width)) minmax(
        20px,
        $cell-width
      );
    grid-template-rows: $cell-height;

    border: $border-width solid $border-color;

    .dateCell {
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;
      align-items: center;
      border-right: $border-width solid $border-color;

      & > span:nth-child(1) {
        font-size: 16px;
        font-weight: 400;
        opacity: 0.5;
      }

      & > span:nth-child(2) {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .today {
      border-bottom: 2px solid red;
    }

    & > .dateCell:nth-last-child(2) {
      border-right: $border-width solid $border-color;
    }

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      max-width: $cell-width;

      & > svg {
        width: 24px;
        height: 24px;
      }
    }
    & > .arrow:nth-child(1) {
      border-right: $border-width solid $border-color;
    }
  }

  .calendarUnite {
    display: flex;
    overflow-x: auto;

    .calendarSideBar {
      display: grid;
      grid-template-columns: minmax(auto, calc($cell-width));

      .timeCell {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: $border-width solid $border-color;

        border-left: $border-width solid $border-color;
        border-right: $border-width solid $border-color;

        & > span {
          opacity: 0.5;
          font-size: 16px;
          font-weight: 600;
        }
      }
      & > .timeCell:nth-last-child(1) {
        border-bottom: 0;
      }
    }
    .calendarBody {
      display: grid;
      grid-template-columns: repeat(7, minmax(20px, $cell-width)) minmax(20px, $cell-width);

      .cell {
        display: flex;
        border-bottom: $border-width solid $border-color;
        border-right: $border-width solid $border-color;
      }

      .odd {
        border-bottom: 0px;
      }
      .lastRowCell {
        border-bottom: 0px;
      }
    }
  }
}
