.wrapper {
  width: 100%;
  height: 100%;
}

.stepWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 88px);

  padding: 24px;

  &.withoutPadding {
    padding: 0;
  }
}

.validateDataStepHeaderWrapper {
  padding: 24px 24px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.validateDataActionsWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.switchWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.validateGridTableWrapper {
  width: 100%;
  height: 100%;

  overflow: scroll;

  padding: 24px 24px 0 24px;
}

.dropzoneWrapper {
  width: 100%;
  height: 100%;
}

.exampleTableWrapper {
  position: relative;
  margin-bottom: 16px;


  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
    pointer-events: none;
  }
}

.selectHeaderTableWrapper {
  height: 100%;
  width: 100%;

  padding: 24px;

  overflow: scroll;
}

.matchColumnsWrapper {
  height: 100%;
  width: 100%;

  padding: 24px 0 24px 24px;

  overflow: scroll;
}

.ignoreColumnButton {
  width: 24px;
  height: 24px;
  padding: 4px !important;
  background-color: #EDF2F8 !important;

  svg {
    width: 16px;
    height: 16px;
  }
}

.columnCell {
  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}

.ignored {
  span {
    color: #9FADC1;
  }
}

.willBecomeWrapper {
  display: flex;
  gap: 14px;
  padding: 16px;

  border-top: 1px solid #E2E7F1;
  border-left: 1px solid #E2E7F1;
  border-top-left-radius: 8px;
}

.headerMatchCellWrapper {
  width: 100%;
}

.columnMatchWrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
}

.formFooter {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #EDF2F7;
  padding: 32px 24px;
  height: 96px;
}

.nextStepButton {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px !important;
  font-weight: 600;

  width: 335px;
}