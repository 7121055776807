.emailItemWrapper {
  display: flex;
  align-items: center;
  list-style: none;
  border-bottom: 1px solid #ebedf0;

  padding: 0 30px 0 4px;

  height: 56px;

  &:hover {
    background-color: #f3f4f6;
  }

  &:last-child {
    border: 0;
  }

  &.unRead {
    div {
      font-weight: 600;
    }
  }
}

.actions {
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 0 0 90px;
}

.mailInformation {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  gap: 10px;

  height: 56px;
  cursor: pointer;
  color: #374151;
  font-weight: 400;

  &:hover {
    color: black;
  }
}

.senderInformation {
  display: flex;
  gap: 10px;
  flex: 0 0 15%;
  min-width: 150px;
}

.senderImage {
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
}

.senderName {
  line-height: 26px;
  width: 100%;
  position: relative;

  &::before {
    content: '&nbsp;';
    visibility: hidden;
  }

  span {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.emailSubject {
  position: relative;
  flex: 1 1 auto;
  text-wrap: nowrap;

  &::before {
    content: '&nbsp;';
    visibility: hidden;
  }

  span {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.emailDate {
  flex: 0 0 15%;
  text-wrap: nowrap;
}