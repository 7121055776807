.btn {
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  @include mediaq(1024px) {
    padding: 10px;
  }
  @include directify($directions) {
    #{directed('margin-right')}: 15px;
  }
  transition: all 0.4s;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &:last-child {
    margin-right: 0;
    // box-shadow: 0px 12px 18px -6px rgba(236,107,28,0.3);
    @include directify($directions) {
      #{directed('margin-right')}: 0;
    }
  }
  &.add_plus {
    // padding: 10px 15px;
    padding: 0;
    width: 38px;
    height: 38px;
  }
  &:before {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
    background-color: $color-accent;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    @include directify($directions) {
      transform: #{directed('translate')};
    }
    z-index: -1;
    content: '';
    top: 0;
    @include directify($directions) {
      #{directed('left')}: 0px;
    }
  }

  p {
    display: flex;
    transition: all 0.3s;
    font-weight: 500;
    color: #444444;
  }

  svg {
    height: 14px;
    width: 14px;
    margin-top: 2px;
    margin-bottom: 0px;
    @include directify($directions) {
      #{directed('margin-right')}: 5px;
    }
    @include directify($directions) {
      #{directed('margin-left')}: 0px;
    }
    transition: all 0.3s;
    fill: #444444;
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    box-shadow: none !important;

    &:before {
      height: 500%;
      width: 225%;
    }
  }

  &:focus,
  &:active,
  &:active:focus {
    &:before {
      transition: all 0s;
    }
  }

  &.square {
    border-radius: 0;
  }

  &.rounded {
    border-radius: 30px !important;
  }

  &.icon {
    padding: 10px 15px;

    &:hover {
      p {
        color: #646777;
      }

      svg {
        fill: #646777;
      }
    }

    &.icon--right {
      svg {
        @include directify($directions) {
          #{directed('margin-right')}: 0px;
          #{directed('margin-left')}: 5px;
        }
        margin-top: 2px;
        margin-bottom: 0;
      }
    }
  }

  &.btn-sm {
    padding: 5px 25px;
    font-size: 14px;
  }

  &.btn-lg {
    padding: 12px 25px;
    font-size: 14px;
  }

  &.btn-secondary {
    background-color: $color-orange;
    border-color: $color-orange;
    color: #fff;
    font-size: 15px;
    line-height: 15.8px;
    font-family: var(--SourceSansPro);
    font-weight: 700;

    &:before {
      background-color: $color-orange-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: $color-orange-hover !important;
      border-color: $color-orange-hover !important;
      /*color: #646777;*/
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
      background-color: $color-orange-hover;
      border-color: $color-orange-hover;
    }
    @include mediaq(767px) {
      font-size: 11px;
      line-height: 13.2px;
    }
  }
  &.btn-secondary-tab {
    background-color: #646777;
    border-color: #646777;
    color: #fff;
    font-size: 15px;
    line-height: 16.8px;
    font-family: var(--SourceSansPro);
    font-weight: 700;

    &:before {
      background-color: $color-orange-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: $color-orange-hover !important;
      border-color: $color-orange-hover !important;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
      background-color: $color-orange-hover;
      border-color: $color-orange-hover;
    }
    @include mediaq(767px) {
      font-size: 11px;
      line-height: 13.2px;
    }
  }

  &.btn-secondary-active {
    background-color: $color-orange-hover !important;
    border-color: $color-orange-hover !important;
    color: #fff;
    font-size: 15px;
    line-height: 16.8px;
    font-family: var(--SourceSansPro);
    font-weight: 700;

    &:before {
      background-color: $color-orange-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: $color-orange-hover !important;
      border-color: $color-orange-hover !important;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
      background-color: $color-orange-hover;
      border-color: $color-orange-hover;
    }
    @include mediaq(767px) {
      font-size: 11px;
      line-height: 13.2px;
    }
  }

  &.btn-outline-secondary {
    border-color: #e7e2e2;
    font-weight: 700 !important;
    &,
    p {
      @include themify($themes) {
        color: themed('colorText');
      }
    }

    svg {
      @include themify($themes) {
        fill: themed('colorText');
      }
    }

    &:before {
      background-color: #e7e2e2;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background: transparent;
      border-color: #e7e2e2;
      color: #444444;

      p {
        color: #444444;
      }

      svg {
        fill: #444444;
      }
    }
  }

  &.btn-primary {
    background-color: $color-orange;
    border-color: $color-orange;
    font-family: var(--SourceSansPro);
    font-size: 17.5px;
    line-height: 21px;
    font-weight: 600;

    &:before {
      background-color: $color-orange-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-orange-hover;
      background-color: $color-orange-hover !important;
    }
    @include mediaq(767px) {
      font-size: 14px;
      line-height: 16.8px;
    }
  }

  &.btn-outline-primary {
    color: $color-orange;
    border-color: $color-orange;

    p {
      color: $color-orange;
    }

    svg {
      fill: $color-orange;
    }

    &:before {
      background-color: $color-orange;
    }
  }

  &.btn-success {
    background-color: $color-accent;
    border-color: $color-accent;

    &:before {
      background-color: $color-accent-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-accent-hover;
    }
  }

  &.btn-loading {
    min-width: 85px;
    background-color: $color-accent;
    border-color: $color-accent;
    color: white;

    &:before {
      background-color: $color-accent-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-accent-hover;
    }
  }

  &.btn-success-wip {
    min-width: 85px;
    background-color: $color-accent;
    border-color: $color-accent;
    color: white;

    &:before {
      background-color: $color-accent-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-accent-hover;
    }
  }

  &.btn-outline-success {
    color: $color-accent;
    border-color: $color-accent;

    p {
      color: $color-accent;
    }

    svg {
      fill: $color-accent;
    }

    &:before {
      background-color: $color-accent;
    }
  }

  &.btn-warning {
    background-color: $color-yellow;
    border-color: $color-yellow;
    color: #ffffff;

    &:before {
      background-color: $color-yellow-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-yellow-hover;
    }
  }

  &.btn-outline-warning {
    color: $color-yellow;
    border-color: $color-yellow;

    p {
      color: $color-yellow;
    }

    svg {
      fill: $color-yellow;
    }

    &:before {
      background-color: $color-yellow;
    }
  }

  &.btn-danger {
    background-color: $color-red;
    border-color: $color-red;

    &:before {
      background-color: $color-red-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-red-hover;
    }
  }

  &.btn-outline-danger {
    color: $color-red;
    border-color: $color-red;

    p {
      color: $color-red;
    }

    svg {
      fill: $color-red;
    }

    &:before {
      background-color: $color-red;
    }
  }

  &,
  &.btn-primary,
  &.btn-danger,
  &.btn-warning,
  &.btn-success,
  &.btn-outline-secondary,
  &.btn-secondary,
  &.btn-outline-primary,
  &.btn-outline-danger,
  &.btn-outline-warning,
  &.btn-outline-success,
  &.icon,
  &.icon.btn-secondary {
    &.disabled {
      background-color: #f5f9fc;
      border-color: #f5f9fc;
      color: #dddddd;
      pointer-events: none;

      p {
        color: #dddddd;
      }

      svg {
        fill: #dddddd;
      }
    }
  }

  &.btn-primary,
  &.btn-danger,
  &.btn-warning,
  &.btn-success {
    p {
      color: #ffffff;
    }

    svg {
      fill: #ffffff;
    }
  }

  &.btn-outline-primary,
  &.btn-outline-danger,
  &.btn-outline-warning,
  &.btn-outline-success {
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: #ffffff;
      background: transparent;
    }
  }

  &.btn-primary,
  &.btn-danger,
  &.btn-warning,
  &.btn-success,
  &.btn-outline-primary,
  &.btn-outline-danger,
  &.btn-outline-warning,
  &.btn-outline-success {
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      p {
        color: #ffffff;
      }

      svg {
        fill: #ffffff;
      }
    }
  }

  &.expand {
    svg {
      width: 0;
      transition: all 0.3s;
    }

    &.expand--load {
      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      svg {
        width: 14px;
        animation: rotating 2s linear infinite;
      }
    }
  }
}

.btn-toolbar {
  margin-top: 15px;
  margin-bottom: 10px;

  & > * {
    @include directify($directions) {
      #{directed('margin-right')}: 15px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: -10px;
  }

  &.btn-toolbar--center {
    & > * {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.btn-group {
  border-radius: 5px;
  margin-bottom: -10px;

  .btn {
    @include directify($directions) {
      #{directed('margin-right')}: 0px;
    }
    padding: 10px 20px;
    font-weight: 500;
  }

  &.btn-group--justified {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .btn {
      width: 100%;
    }
  }

  &.btn-group--icons {
    .btn {
      padding: 7px 20px;
      line-height: 14px;
      @include mediaq(991px) {
        padding: 7px;
      }
    }
  }

  &.open .dropdown-toggle {
    box-shadow: none;
  }
}

button:focus,
button:active {
  outline: none;
}

.open > .dropdown-toggle.btn-default,
.btn-default:active:focus,
.btn-default:active:focus,
.btn-default.focus,
.btn-default:focus {
  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.btn-gray {
  padding: 8px 20px;
  font-weight: 700;
  font-size: 15px;
  color: #646777;
  line-height: 16.8px;
  font-family: var(--SourceSansPro);
  background: #f0f0f0;
  border: 1px solid #f0f0f0;
  &:hover {
    background: #f2f5f8;
  }
}
// Add task

.addtask_action_btns {
  display: block;
  .btn {
    min-width: 70px;
    margin-bottom: 5px !important;
    margin-right: 7px !important;
    &:last-child {
      margin-right: 0;
    }
    &.viewNoteBtn {
      min-width: 110px;
    }
  }
}
.button_group_custom {
  margin-top: 5px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.button_group_custom_email {
  margin-top: 5px;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: baseline;
}
