.continer {
  width: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}
