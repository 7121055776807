.date-picker {
  width: 100%;

  & > div, .react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__time-list {
    padding: 0;
    overflow-x: hidden;
  }

  .react-datepicker__time-list-item {
    width: 50px;
  }

  .react-datepicker__time {
    overflow: hidden;
  }

  &.date-picker--interval {
    display: flex;

    & > div, .react-datepicker-wrapper, .react-datepicker__input-container {
      max-width: 170px;
    }
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;

    @include themify($themes) {
      fill: themed('colorText');
    }
  }

  .react-datepicker__header {
    background-color: $color-blue;
    border-color: $color-blue;
    border-radius: 0;
  }

  .react-datepicker__current-month, .react-datepicker-time__header,
  .react-datepicker__day-name, .react-datepicker__time-name {
    color: #ffffff;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ffffff;

    &:hover {
      border-left-color: #ffffff;
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ffffff;

    &:hover {
      border-right-color: #ffffff;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: $color-blue;
    transition: all 0.3s;

    &:hover {
      background-color: $color-blue-hover;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    border-bottom-color: $color-blue;
    margin-top: -7px;
  }
  .react-datepicker-popper{
    z-index: 4;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
    display: inline-flex;
  }

  .react-datepicker__time-container {

    @include themify($themes) {
      @include directify($directions) {
        #{directed('border-left')}: 1px solid themed('colorBorder');
      }
    }
    width: 85px!important;
  }
  .react-datepicker__time-box {
    width: 85px!important;
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__time-list-item--selected {
    background-color: $color-blue-hover !important;
  }

  .react-datepicker__month-container, .react-datepicker__time-list {
    @include themify($themes) {
      background-color: themed('colorBackground') !important;
    }
  }

  .react-datepicker__header, .react-datepicker__day--selected, .react-datepicker__time-list-item--selected, .react-datepicker__day--keyboard-selected {
    @include themify($themes) {
      background-color: themed('colorHeaderDataPicker') !important;
      color: themed('colorText');
    }
  }

  .react-datepicker__day--weekend, .react-datepicker__day, .react-datepicker__time-list {
    @include themify($themes) {
      color: themed('colorText');
    }
  }

  .react-datepicker__day:hover, .react-datepicker__time-list-item:hover {
    @include themify($themes) {
      background-color: themed('colorHover') !important;
    }
  }
  
}
#contacts_accordion,.modal {
  .date-picker.date-picker--interval{
    flex-wrap: wrap;
  }
  .react-datepicker-wrapper{
    max-width: 100% !important;
    .react-datepicker__input-container{max-width: 100%;display: block;}
    input{
      display:block;
      width: 100%;
      // height: calc(1.5em + 0.75rem + 2px);
      // padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      height: 32px;
      padding: 5px 10px;
      font-size: 1rem;
      font-weight: 400;
      // line-height: 1.5;
      color: #495057;
      vertical-align: middle;
      background-color: rgba(224, 231, 255, 0.2);
      // border: 1px solid #E0E7FF;
      border-radius: 0.25rem;
      border: 1px solid #E0E7FF;
      &:focus {
          border: 1px solid #ff5a16;
          outline: none;
      }
    }
  }
}



.theme-light .date-picker .react-datepicker__header,
.theme-light .date-picker .react-datepicker__day--selected,
.theme-light .date-picker .react-datepicker__time-list-item--selected,
.theme-light .date-picker .react-datepicker__day--keyboard-selected {
  background-color: #ec6b1c !important;
  color: #fff !important;
}

.theme-light .date-picker .react-datepicker__day:hover, .theme-light .date-picker .react-datepicker__time-list-item:hover
{
  background-color: #d85200 !important;
  color: #fff !important;
}