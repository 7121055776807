.loggedCell {
  font-weight: 400;
  color: #6B7280;
}

.debtorCell {
  color: #F3742B;
  font-weight: 700;
}

.removeButton {
  border: 0;
  background-color: transparent;
  padding: 0;

  svg {
    width: 24px;
    height: 24px;
  }
}