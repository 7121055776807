.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.row {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.editorWrapper>div {
  border-radius: 8px;
}

.actionWrapper {
  display: flex;
  gap: 16px;
  justify-content: end;
}

.labelWrapper {
  color: black;
  margin-bottom: 8px;
  text-wrap: nowrap;
  width: 100%;
}

.label {
  margin: 0;
  margin-right: 8px;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}

.error {
  margin: 0;
  color: #ef4444;
  font-weight: 500;
  line-height: 24px !important;
  font-size: 16px;
}