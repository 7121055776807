.emailFooterWrapper {
  flex: 0 0 60px;
  border-top: 1px solid #ebedf0;
  padding: 16px;
}

.actionWrapper {
  display: flex;
  gap: 10px;
}

.replyButton {
  background-color: #1d4ed8;
  color: white;
  padding: 10px 10px;
  width: 95px;

  font-size: 16px;
  font-weight: 500;

  &:hover{
    background-color: #1d40b0;
    color: white;
  }

  svg {
    min-width: 20px ;
    height: 20px;
  }
}

.forwardButton {
  background-color: white;
  color: black;
  padding: 10px 10px;
  width: 120px;

  font-size: 16px;
  font-weight: 500;

  border: 1px solid #D1D5DB;

  &:hover{
    color: black;
  }

  svg {
    min-width: 20px ;
    height: 20px;
  }
}