.wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 400px;
}

.fieldWrapper {
  display: flex;
  gap: 15px
}

.labelWrapper {
  span {
    font-size: 16px;
    color: #979ca7;
    font-weight: 400;
  }
}

.actionWrapper {
  display: flex;
  justify-content: space-between;
}