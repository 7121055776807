.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.replyField {
  background-color: #F9FAFB;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
}

.bodyField {
  height: 150px;
  resize: none;
}


.actionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.sendButton {
  background-color: #1d4ed8;
  color: white;
  padding: 10px 20px;
  width: 95px;

  font-size: 16px;
  font-weight: 500;
  gap: 5px;

  &:hover {
    background-color: #1d40b0;
    color: white;
  }

  svg {
    min-width: 16px;
    height: 16px;
  }
}

.right {
  transform: rotate(90deg);
}