.wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 50%);
  height: calc(100vh - 140px);
  overflow-y: scroll;
  background-color: white;

  padding: 38px 43px 30px 43px;
  border-radius: 6px;
  box-shadow:
    0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  z-index: 1000;

  .btns {
    margin-top: auto;
    padding-top: 20px;
    display: flex;

    gap: 10px;

    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 60px;

    &>button {
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      border: 0;
      border-radius: 6px;
    }

    &>button:nth-child(1) {
      padding: 10px 16px;
      background: #e4e8f0;
    }

    &>button:nth-child(2) {
      padding: 10px 40px;
      background: #f37c20;
      color: white;
    }
  }
}

.layout {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 150;
  background-color: black;
  top: 0;
  left: 0;
  opacity: 0.7;
}


.headerWrapper {
  display: flex;
  justify-content: end;
  width: 100%;
}

.closeIcon {
  padding: 0;
  background-color: transparent;
  border: 0;

  svg {
    width: 28px;
    height: 28px;

    path {
      stroke: #9CA3AF;
    }
  }
}