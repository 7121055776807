.wrapper {
  display: flex;
  gap: 16px;

  &.vertical {
    flex-direction: column;
  }
}

.assignPropertySection {
  min-width: 380px;
  width: 380px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.vertical {
    width: 100%;
    flex-direction: column;
  }
}

